interface PhoneOptions {
  separators?: string;
  prefix?: string;
}

const elitePatterns = ['812', '800'];
export default function normalizeUserInputPhone(
  phone: string,
  options?: PhoneOptions,
): string {
  // Если хочешь тут хоть что-то понять, иди в тесты, это поможет
  const digits = phone.replace(/[^0-9]+/gi, '');

  if (!digits) return formatDigits('', options);

  if (
    digits.length > 11 &&
    ['78', '88', '77'].some(pattern => digits.startsWith(pattern)) &&
    !elitePatterns.some(pattern => digits.slice(1).startsWith(pattern))
  ) {
    return formatDigits(digits.slice(2, 12), options);
  }

  if (
    ['8', '7'].some(pattern => digits.startsWith(pattern)) &&
    !elitePatterns.some(pattern => digits.startsWith(pattern))
  ) {
    return formatDigits(digits.slice(1, 11), options);
  }

  return formatDigits(digits.slice(0, 10), options);
}

export function formatDigits(
  digits: string,
  options: PhoneOptions = {},
): string {
  const {separators = 'p XXX XXX XX XX', prefix = '+7'} = options;
  const inputDigits = [...digits];

  let output = '';

  for (const char of separators) {
    switch (char) {
      case 'p':
        output += prefix;
        break;
      case 'X':
        output += inputDigits.splice(0, 1)[0];
        break;
      default:
        output += char;
    }
    if (!inputDigits.length) break;
  }

  if (inputDigits.length) output += inputDigits.join('');

  return output;
}
