import ifDevElse from './if-dev-else';

export default function canonicalWithTrailingSlash(
  host: string,
  path: string,
): string {
  const pathname = path.endsWith('/') ? path : `${path}/`;
  return constructURL(host, pathname);
}

export function canonicalWithoutTrailingSlash(
  host: string,
  path: string,
): string {
  return constructURL(host, path);
}

function constructURL(host, path) {
  const protocol = ifDevElse('http:', 'https:');
  return `${protocol}//${host}${path}`;
}
