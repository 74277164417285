/* eslint-disable react/no-danger */
import React, {FunctionComponent} from 'react';
import {useQuery} from '@apollo/client';
import {useRouteMatch} from 'react-router';
import {useMeta} from 'client/contexts/Meta';
import {useLocation} from 'client/contexts/Location';
import {WithContext, LocalBusiness, Product} from 'schema-dts';
import {GeoUnitSlugType} from 'client/types/slug';
import {useCity} from 'client/contexts/City';
import formatNumber from '@pollex/utils/format-number';

import handsRuGetProductStructuredData, {
  HandsRuGetProductStructuredDataData,
  HandsRuGetProductStructuredDataVariables,
} from 'query-wrappers/HandsRuGetProductStructuredData';

import handsRuGetReviewSlice, {
  HandsRuGetReviewSliceData,
  HandsRuGetReviewSliceVariables,
} from 'query-wrappers/HandsRuGetReviewSlice';

import {
  CATALOG_PATHS,
  getDataTypeFromPath,
  getDataFromPage,
  isLocalBusinessData,
  isProductData,
  getPageTypeFromPath,
} from './utils';

interface OwnProps {
  kind: 'LocalBusiness' | 'Product';
}

const LocalBusinessOrProductStructuredData: FunctionComponent<OwnProps> = ({
  kind,
}) => {
  const match = useRouteMatch<{slug: string; geo: GeoUnitSlugType}>(
    CATALOG_PATHS,
  );
  const profileMatch = useRouteMatch<{slug: string; geo: GeoUnitSlugType}>([
    '/profile/:slug/',
  ]);

  const {host} = useLocation();
  const {officeStreetAddress, botType} = useMeta();
  const {current: city} = useCity();
  const isBot = botType === 'G' || botType === 'Y';

  const dataType = getDataTypeFromPath(match?.path);
  const pageType = getPageTypeFromPath(match?.path);

  const {data} = useQuery<
    HandsRuGetProductStructuredDataData,
    HandsRuGetProductStructuredDataVariables
  >(handsRuGetProductStructuredData, {
    variables: {
      slug: match?.params.slug as string,
      pageType,
      geo: match?.params.geo as string,
      isServicePage: dataType === 'SERVICE',
      isObjectPage: dataType === 'OBJECT',
      isCategoryPage: dataType === 'CATEGORY',
    },
    skip: !match?.params.slug || !!profileMatch,
  });

  const {data: reviewSliceData} = useQuery<
    HandsRuGetReviewSliceData,
    HandsRuGetReviewSliceVariables
  >(handsRuGetReviewSlice, {
    variables: {
      slug: match?.params.slug as string,
      pageType,
      geo: match?.params.geo as string,
      filterType: 'ALL',
      page: 1,
      perPage: isBot ? 20 : 3,
      dataType,
    },
    skip: !match?.params.slug || !!profileMatch,
  });

  if (profileMatch) {
    return null;
  }

  const microData: WithContext<LocalBusiness> | WithContext<Product> =
    kind === 'LocalBusiness'
      ? {
          '@context': 'https://schema.org',
          '@type': 'LocalBusiness',
          name: 'Руки',
          image: `https://${host}/media/logogoogle.png`,
          address: {
            '@type': 'PostalAddress',
            streetAddress:
              city.name === 'Москва' ? officeStreetAddress : city.name,
            addressLocality: city.name,
            addressCountry: 'RU',
          },
          telephone: '8-800-302-70-56',
          url: `https://${host}`,
          priceRange: 'РР',
        }
      : {
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: 'Руки',
          image: `https://${host}/media/logogoogle.png`,
          url: `https://${host}`,
        };

  if (match) {
    microData.url = `https://${host}${match.url}`;
  }

  const productData = getDataFromPage(dataType, data);

  if (productData && reviewSliceData) {
    const {name, description, price, minimumPrice} = productData;

    const {
      reviewSlice: {entries: reviews, totalCount: reviewCount},
      stats: {averageRating},
    } = reviewSliceData;

    microData.name = `${name} ${city.prepositional}`;
    microData.description = description;

    const priceData = price?.amount
      ? `от ${formatNumber(price.amount)} ${price?.unitMorphology || '₽'}`
      : `от ${formatNumber(minimumPrice?.amount ?? 1500)} ${
          price?.unitMorphology || '₽'
        }`;

    if (isLocalBusinessData(microData)) {
      microData.priceRange = priceData;
    }

    if (isProductData(microData)) {
      microData.offers = {
        '@type': 'Offer',
        price: priceData,
      };
    }

    if (reviewCount) {
      microData.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: averageRating,
        reviewCount,
        bestRating: '5',
        worstRating: '0',
      };

      microData.review = reviews.map(({order: {client}, createdAt, text}) => ({
        '@type': 'Review',
        author: {'@type': 'Person', name: client.name},
        datePublished: createdAt,
        reviewBody: text,
        itemReviewed: {'@type': 'Thing', name: client.name},
      }));
    }
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(microData),
      }}
    />
  );
};

export default LocalBusinessOrProductStructuredData;
