import {CategorySlugType} from 'client/types/slug';
import noop from 'lodash/noop';
import {ThemeType, ThemeBag} from './types';

export const DEFAULT_THEME: ThemeType = {
  slug: 'ruki',
  accentText: '#FFFFFF',
  defaultText: '#000000',
  defaultBackground: '#21A3FF',
  accentBackground: '#000FFF',
  name: 'Руки, сервис домашнего ремонта',
  serviceCount: 0,
};

export const SPECIALIST_DOCUMENT_THEME = {
  slug: 'specialist-documents',
  accentText: '#FFFFFF',
  defaultText: '#000000',
  defaultBackground: '#FFFFFF',
  accentBackground: '#00CC99',
  name: '',
  serviceCount: 0,
};

export const DEFAULT_BAG: ThemeBag = {
  ...DEFAULT_THEME,
  cache: new Map<CategorySlugType, ThemeType>(),
  updateSlug: noop,
  isReal: false,
};
