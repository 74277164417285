export const UNBREAKABLE_WHITESPACE = '\u00a0';
export const UNBREAKABLE_HYPHEN = '\u2011';
export const SOFT_HYPHEN = '\u00ad';
export const DASH = '\u2014';

const REGEX = {
  whitespace: / /g,
  someDash: /–/g,
  hyphenInPlaceOfDash: /( )(-)( )/gi,
  dashSurroundedBySpaces: / — /g,
  breakableHyphen: /-/gi,
  numberFollowedBySpace: /(\d+) /gi,
  wordWithPrepositionAndSpace: /([^а-я]+)(и|без|в|вне|во|о|для|до|за|из|изо|к|ко|на|над|об|от|по|под|при|про|с|со|у|г\.|м\.) /gi,
  softHyphen: /[ЙЦКНГШЩЗХЪФВПРЛДЖЧСМТЬБ]*[ЁУЕЫАОЭЯИЮ][ЙЦКНГШЩЗХЪФВПРЛДЖЧСМТЬБ]*?(?=$|[^А-ЯЁ]|[ЦКНГШЩЗХФВПРЛДЖЧСМТБ]?[ЁУЕЫАОЭЯИЮ]|Й[АИУЕО])(?!\s|$)/gi,
  prepositionWithSpace: /^(и|без|в|вне|во|о|для|до|за|из|изо|к|ко|на|над|об|от|по|под|при|про|с|со|у|г\.|м\.) /gi,
};

export default function typographize(s?: string): string {
  if (!s) return '';

  return s
    .replace(REGEX.hyphenInPlaceOfDash, `${UNBREAKABLE_WHITESPACE}${DASH} `)
    .replace(REGEX.dashSurroundedBySpaces, `${UNBREAKABLE_WHITESPACE}${DASH} `)
    .replace(REGEX.breakableHyphen, UNBREAKABLE_HYPHEN)
    .replace(REGEX.numberFollowedBySpace, `$1${UNBREAKABLE_WHITESPACE}`)
    .replace(REGEX.wordWithPrepositionAndSpace, `$1$2${UNBREAKABLE_WHITESPACE}`)
    .replace(REGEX.prepositionWithSpace, `$1${UNBREAKABLE_WHITESPACE}`)
    .replace(REGEX.someDash, DASH);
}

export function shy(s: string): string {
  return s.replace(REGEX.softHyphen, `$&${SOFT_HYPHEN}`);
}

export function nobr(s: string): string {
  return s.replace(REGEX.whitespace, UNBREAKABLE_WHITESPACE);
}
