import {
  ServiceSlugType,
  CategorySlugType,
  ObjectSlugType,
  GeoUnitSlugType,
  SpecializationSlugType,
  SpecializationPageSlugType,
  ServicePageSlugType,
  CategoryPageSlugType,
  ObjectPageSlugType,
} from 'client/types/slug';

interface SlugSources {
  service: {
    [x: string]: any;
  };
  category: {
    [x: string]: any;
  };
  object: {
    [x: string]: any;
  };
  geoUnit: {
    [x: string]: any;
  };
  specialization: {
    [x: string]: any;
  };
}

interface SlugResults {
  service: ServiceSlugType | ServicePageSlugType;
  category: CategorySlugType | CategoryPageSlugType;
  object: ObjectSlugType | ObjectPageSlugType;
  geoUnit: GeoUnitSlugType;
  specialization: SpecializationSlugType | SpecializationPageSlugType;
}

export default function pluckSlugsFromQuery({
  service,
  category,
  object,
  geoUnit,
  specialization,
}: Partial<SlugSources>): Partial<SlugResults> {
  return {
    object: (object && object.slug) || '',
    category: (category && category.slug) || '',
    service: (service && service.slug) || '',
    geoUnit: (geoUnit && geoUnit.slug) || '',
    specialization: (specialization && specialization.slug) || '',
  };
}
