import React, {
  useContext,
  useEffect,
  FunctionComponent,
  useState,
  useCallback,
} from 'react';
import {useQuery} from '@apollo/client';
import {CategorySlugType} from 'client/types/slug';
import {useLocation} from 'client/contexts/Location';
import handsRuGetCategories, {
  HandsRuGetCategoryData,
} from 'query-wrappers/HandsRuGetCategories';
import {Helmet} from 'react-helmet';
import {
  themesToCache,
  themeSlugFromURLParts,
  variablesFromTheme,
} from './utils';
import {ThemeBag} from './types';
import {DEFAULT_BAG, DEFAULT_THEME} from './constants';

export * from './utils';
export * from './constants';
export * from './types';

const Context = React.createContext<ThemeBag>(DEFAULT_BAG);

const ThemeProvider: FunctionComponent = ({children}) => {
  const {data, error} = useQuery<HandsRuGetCategoryData>(handsRuGetCategories);
  const categories = data?.categories || [];
  const cache = new Map(categories.length ? themesToCache(categories) : []);
  const location = useLocation();
  const [currentSlug, setCurrentSlug] = useState(
    themeSlugFromURLParts(cache, location),
  );
  const theme = (currentSlug && cache.get(currentSlug)) || DEFAULT_THEME;
  const isReal = !!currentSlug && currentSlug !== DEFAULT_THEME.slug;

  useEffect(() => {
    const newSlug = themeSlugFromURLParts(cache, location);
    if (!newSlug) return;
    setCurrentSlug(newSlug);
  }, [
    location.location.pathname,
    location.location.search,
    location.query?.category,
  ]);

  const updateSlug = useCallback((slug: CategorySlugType): void => {
    setCurrentSlug(slug);
  }, []);

  if (error) throw error;

  const style = variablesFromTheme(theme)
    .map(v => v.join(':'))
    .join(';');

  return (
    <>
      <Helmet
        htmlAttributes={{
          // @ts-ignore
          style,
        }}
      />
      <Context.Provider value={{...theme, isReal, updateSlug, cache}}>
        {children}
      </Context.Provider>
    </>
  );
};

export function useTheme(): ThemeBag {
  return useContext(Context);
}

export function useThemeChange(slug: CategorySlugType | undefined): void {
  const theme = useTheme();

  useEffect(() => {
    if (!slug || slug === theme.slug) return;
    theme.updateSlug(slug);
  }, [slug]);
}

export function useTileColors(
  slug: CategorySlugType,
  isAccent: boolean = false,
): [string, string] {
  const {cache, ...restOfCategory} = useContext(Context);
  const category = cache.get(slug);

  if (!category) {
    return [restOfCategory.defaultBackground, restOfCategory.defaultText];
  }

  const {
    defaultBackground,
    defaultText,
    accentBackground,
    accentText,
  } = category;

  if (isAccent) {
    return [accentBackground, accentText];
  }

  return [defaultBackground, defaultText];
}

export default {
  Provider: ThemeProvider,
  Context,
  Consumer: Context.Consumer,
};
