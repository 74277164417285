import handsRuTryCatalogItemForCity from 'queries/handsRuTryCatalogItemForCity.graphql';
import {CityID} from 'client/types/id';
import {
  CategoryPageSlugType,
  ObjectPageSlugType,
  ServicePageSlugType,
} from 'client/types/slug';

export type HandsRuTryCatalogItemForCityVariables = {
  cityId: CityID;
  slug: ServicePageSlugType | ObjectPageSlugType | CategoryPageSlugType;
  isCategoryPage: boolean;
  isObjectPage: boolean;
  isServicePage: boolean;
};

export type HandsRuTryCatalogItemForCityData<
  T extends 'categoryPage' | 'objectPage' | 'servicePage' | 'specialistPage'
> = {catalog: Record<T, {item: {isVisibleForCity: boolean}}>};

export default handsRuTryCatalogItemForCity;
