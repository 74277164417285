import qs from 'qs';
import {matchPath} from 'react-router-dom';
import {CatalogDataTypeEnum} from 'client/types/page-type';
import * as H from 'history';

export type RouteToScreenName = Readonly<{
  path: string;
  screenName: ScreenName;
  exact: boolean;
}>;

export interface LocationQueryType {
  [key: string]: any;
}

export interface LocationStateType {
  query: LocationQueryType;
  screenName: ScreenName;
}

export interface LocationBag extends LocationStateType {
  host: string;
  location: H.Location;
  history: H.History;
}

export const SCREENNAME_CONFIG = [
  {
    path: '/review/add/',
    exact: true,
    screenName: 'system_review_form',
  },
  {
    path: '/pik/order/',
    exact: true,
    screenName: 'partner_order',
  },
  {
    path: '/melana/order/',
    exact: true,
    screenName: 'partner_order',
  },
  {
    path: '/ampm/order/',
    exact: true,
    screenName: 'partner_order',
  },
  {
    path: '/auchan/order/',
    exact: true,
    screenName: 'auchan_order',
  },
  {
    path: '/partner/order/',
    exact: true,
    screenName: 'partner_order',
  },
  {
    path: '/category/',
    exact: true,
    screenName: 'classifier',
    catalogDataType: () => 'CATEGORY',
  },
  {
    path: '/reviews/',
    exact: true,
    screenName: 'reviews',
  },
  {
    path: '/:type/:slug/reviews/',
    exact: true,
    screenName: 'reviews',
    catalogDataType: ({type}) => type.toUpperCase(),
  },
  {
    path: '/:type/:slug/price/',
    exact: true,
    screenName: 'price',
    catalogDataType: ({type}) => type.toUpperCase(),
  },
  {
    path: '/service/:slug/',
    exact: true,
    screenName: 'service',
    catalogDataType: () => 'SERVICE',
  },
  {
    path: '/service/:slug/smeta/',
    exact: true,
    screenName: 'service',
    catalogDataType: () => 'SERVICE',
  },
  {
    path: '/service/:slug/:geo/',
    exact: true,
    screenName: 'service_specific_geo',
    catalogDataType: () => 'SERVICE',
  },
  {
    path: '/object/:slug/',
    exact: true,
    screenName: 'object',
    catalogDataType: () => 'OBJECT',
  },
  {
    path: '/object/:slug/:geo/',
    exact: true,
    screenName: 'object_specific_geo',
    catalogDataType: () => 'OBJECT',
  },
  {
    path: '/category/:slug/',
    exact: true,
    screenName: 'category',
    catalogDataType: () => 'CATEGORY',
  },
  {
    path: '/category/:slug/geo/',
    exact: true,
    screenName: 'category_all_geos',
    catalogDataType: () => 'CATEGORY',
  },
  {
    path: '/category/:slug/geo/:geo/',
    exact: true,
    screenName: 'category_specific_geo',
    catalogDataType: () => 'CATEGORY',
  },
  {
    path: '/success/',
    exact: true,
    screenName: 'order_success',
  },
  {
    path: '/profile/:slug/',
    exact: true,
    screenName: 'page_specialist_profile',
  },
  {
    path: '/specialist/',
    exact: true,
    screenName: 'specialist_register',
  },
  {
    path: '/specialist/documents/',
    exact: true,
    screenName: 'specialist_documents',
  },
  {
    path: '/specialist/documents/:step/',
    exact: true,
    screenName: 'specialist_documents',
  },
  {
    path: '/fulltimer/register/',
    exact: true,
    screenName: 'fulltimer_register_form',
  },
  {
    path: '/specialist/register/',
    exact: true,
    screenName: 'specialist_register_form',
  },
  {
    path: '/specialist/:slug/register/',
    exact: true,
    screenName: 'specialist_register_form_specialized',
  },
  {
    path: '/specialist/success/',
    exact: true,
    screenName: 'specialist_register_success',
  },
  {
    path: '/specialist/:slug/',
    exact: true,
    screenName: 'specialist_register_specialized',
  },
  {
    path: '/compensation/',
    exact: true,
    screenName: 'client_compensation',
  },
  {
    path: '/',
    exact: true,
    screenName: 'main',
  },
  {
    path: '/index.html',
    exact: true,
    screenName: 'main',
  },
  {
    path: '/company/about/',
    exact: true,
    screenName: 'company_about',
  },
  {
    path: '/client/orders/',
    exact: true,
    screenName: 'client_orders',
  },
  {
    path: '/client/orders/edit/',
    exact: true,
    screenName: 'order_edit',
  },
  {
    path: '/client/cart/',
    exact: true,
    screenName: 'client_cart',
  },
  {
    path: '/company/contact-us/',
    exact: true,
    screenName: 'company_contacts',
  },
  {
    path: '/vacancies/',
    exact: true,
    screenName: 'vacancies',
  },
  {
    path: '/documents/:slug/',
    exact: true,
    screenName: 'static_documents',
  },
  {
    path: '/:slug/',
    exact: false,
    screenName: 'static',
  },
  {
    path: '*',
    exact: false,
    screenName: '404',
  },
] as const;

export type ScreenName = typeof SCREENNAME_CONFIG[number]['screenName'];

export function screenNameFromLocation({pathname}: H.Location): ScreenName {
  for (const {path, screenName, exact} of SCREENNAME_CONFIG) {
    if (matchPath(pathname, {path, exact})) return screenName;
  }

  return 'static';
}

export function catalogItemFromLocation({
  pathname,
}: H.Location): {dataType: CatalogDataTypeEnum; slug: string} | null {
  for (const {path, exact, ...config} of SCREENNAME_CONFIG) {
    const match = matchPath(pathname, {path, exact});

    if (match?.params && 'catalogDataType' in config) {
      const {
        params,
        params: {slug},
      } = match as any;

      const dataType = config.catalogDataType(params) as CatalogDataTypeEnum;
      if (dataType && slug) return {slug, dataType};
    }
  }

  return null;
}

export function geoFromLocation(pathname: string): string | null {
  for (const {path, exact} of SCREENNAME_CONFIG) {
    const match = matchPath<{geo?: string}>(pathname, {path, exact});

    if (match) {
      return match.params.geo || null;
    }
  }

  return null;
}

export function getQuery(location: Partial<H.Location>): LocationQueryType {
  if (!location?.search) return {};
  return qs.parse(location.search.slice(1));
}
