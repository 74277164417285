/* eslint no-use-before-define: 'error' */
/* eslint-disable no-use-before-define */
import React, {useContext, FunctionComponent} from 'react';
import {useQuery} from '@apollo/client';
import fromPairs from 'lodash/fromPairs';
/* eslint-enable no-use-before-define */

import template from 'lodash/template';

import {
  DEFAULT_BAG as DEFAULT_LOCATION_STATE,
  LocationBag,
  useLocation,
} from 'client/contexts/Location';

import {DEFAULT_BAG as DEFAULT_CITY_BAG, useCity} from 'client/contexts/City';
import {CityType} from 'client/types/city';
import {OrderSource} from 'client/types/order-source';
import handsRuGetUserExperiments, {
  HandsRuGetUserExperimentData,
} from 'query-wrappers/HandsRuGetUserExperiments';

import {EventEmitter} from 'events';

export const OFFICE = Object.freeze({
  officeCoords: [55.797605, 37.522216],
  officeStreetAddress:
    'Москва, Проезд Аэропорта, дом 8, строение 14, помещение 3.',
  officeZip: 'Москва, а/я 9, 125252',
});

interface FilledTemplates {
  siteTitle: string;
  metaDescription: string;
  ogDescription: string;
  ogImage: string;
  ogUrl: string;
}

const TEMPLATES = {
  siteTitle: template('Руки, сервис домашнего ремонта <%= prepositional %>'),

  metaDescription: template(
    'Руки — это сервис домашнего ремонта <%= prepositional %>. ' +
      'Просто выберите, что сделать, и укажите удобное время. ' +
      'Стоимость ремонта известна заранее и не поменяется в последний момент. ' +
      'Установим унитаз или смеситель, починим стиральную машину и холодильник, ' +
      'устраним засор в трубах и починим проводку, ' +
      'повесим люстру и подключим телевизор, соберём шкаф или кровать, ' +
      'установим входную или межкомнатную дверь. ' +
      'Работаем по договору, следим за чистотой и убираем после работы.',
  ),

  ogDescription: template(''),

  ogImage: template(`https://<%= host %>/social.jpg`),

  ogUrl: template('https://<%= host %><%= pathname %>'),
};

function fillTemplates(city: CityType, location: LocationBag): FilledTemplates {
  const filled = {};

  for (const name of Object.keys(TEMPLATES)) {
    filled[name] = TEMPLATES[name]({
      prepositional: city.prepositional,
      host: location.host,
      pathname: location.location.pathname,
    });
  }

  return filled as FilledTemplates;
}

export type ExperimentConfig = {
  /* eslint-disable camelcase */
  /* eslint-enable camelcase */
};

export type BotType = 'Y' | 'G' | 'O' | 'N';

interface MetaProps {
  experiments: ExperimentConfig;
  source: OrderSource;
  isHuman: boolean;
  isMobile: boolean;
  signal?: EventEmitter;
  botType: BotType;
  referrer: string;
}

export type MetaBag = typeof OFFICE & FilledTemplates & MetaProps;

export const DEFAULT_STATE: MetaBag = {
  source: 'HANDS_RU',
  isHuman: true,
  isMobile: false,
  experiments: {},
  signal: undefined,
  botType: 'N',
  referrer: '',
  ...OFFICE,
  ...fillTemplates(DEFAULT_CITY_BAG.current, DEFAULT_LOCATION_STATE),
};

const Context = React.createContext<MetaBag>(DEFAULT_STATE);

const MetaProvider: FunctionComponent<MetaProps> = ({
  children,
  experiments,
  source,
  isHuman,
  isMobile,
  signal,
  botType,
  referrer,
}) => {
  const {current: city} = useCity();
  const location = useLocation();
  const {data} = useQuery<HandsRuGetUserExperimentData>(
    handsRuGetUserExperiments,
    {ssr: false},
  );

  const state = {
    source,
    isHuman,
    isMobile,
    experiments,
    signal,
    botType,
    referrer,
    ...OFFICE,
    ...fillTemplates(city, location),
  };

  const experimentState = data?.userActiveExperiments
    ? fromPairs(data.userActiveExperiments.map(e => [e.name, e.variant]))
    : state.experiments;

  return (
    <Context.Provider value={{...state, experiments: experimentState}}>
      {children}
    </Context.Provider>
  );
};

export function useMeta(): MetaBag {
  return useContext(Context);
}

export default {
  Context,
  Provider: MetaProvider,
  Consumer: Context.Consumer,
};
