import handsRuDeleteCartItem from 'queries/handsRuDeleteCartItem_2.graphql';
import {CartType} from 'client/types/client-cart';

export interface HandsRuDeleteCartItemData {
  deleteCartItem: {
    cart: CartType;
  };
}

export interface HandsRuDeleteCartItemVariables {
  serviceSlug: string;
}

export default handsRuDeleteCartItem;
