import Cookies from 'universal-cookie';
import {getEnv} from 'client/utils/env';
import {btoa} from 'client/utils/base64';

const cookies = new Cookies();

interface AuthHeaders {
  'X-CSRFToken': string;
  Authorization?: string;
}

export default function apiAuth(): AuthHeaders {
  const DEFAULT_HEADERS = {
    'X-CSRFToken': cookies.get('csrftoken'),
  };

  const {
    HANDS_RU_BASIC_ENABLED,
    HANDS_RU_BASIC_USERNAME,
    HANDS_RU_BASIC_PASSWORD,
  } = getEnv();

  if (
    !HANDS_RU_BASIC_ENABLED ||
    !HANDS_RU_BASIC_USERNAME ||
    !HANDS_RU_BASIC_PASSWORD
  ) {
    return DEFAULT_HEADERS;
  }

  const credentials = `${HANDS_RU_BASIC_USERNAME}:${HANDS_RU_BASIC_PASSWORD}`;

  return {
    ...DEFAULT_HEADERS,
    Authorization: `Basic ${btoa(credentials)}`,
  };
}
