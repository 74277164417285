/* eslint no-underscore-dangle: off */
interface PossibleType {
  name: string;
}

interface IntrospectionType {
  kind:
    | 'SCALAR'
    | 'OBJECT'
    | 'INTERFACE'
    | 'UNION'
    | 'ENUM'
    | 'INPUT_OBJECT'
    | 'LIST';
  name: string;
  possibleTypes: PossibleType[];
}

interface IntrospectionSchema {
  types: IntrospectionType[];
}

interface IntrospectionQueryResultData {
  __schema: IntrospectionSchema;
}

interface PossibleTypesMap {
  [supertype: string]: string[];
}

export default function introspectionSchemaToPossibleTypes(
  introspectionQueryResultData: IntrospectionQueryResultData,
): PossibleTypesMap {
  if (!introspectionQueryResultData) return {};

  const possibleTypes = {};

  introspectionQueryResultData.__schema.types.forEach(supertype => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(
        subtype => subtype.name,
      );
    }
  });

  return possibleTypes;
}
