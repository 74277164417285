import handsRuGetUserExperiments from 'queries/handsRuGetUserExperiments.graphql';

export interface HandsRuGetUserExperimentData {
  userActiveExperiments: {
    name: string;
    variant: string;
  }[];
}

export default handsRuGetUserExperiments;
