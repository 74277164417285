import React, {FunctionComponent, MouseEvent, ReactNode} from 'react';

import css from 'common-tags/lib/stripIndent';
import {Helmet} from 'react-helmet';

interface OwnProps {
  id?: string;
  title?: ReactNode;
  caption?: ReactNode;
}

/* eslint jsx-a11y/anchor-is-valid: off */
const DefaultAction: FunctionComponent = () => (
  <Action
    onClick={(e: MouseEvent) => {
      e.preventDefault();
      window.location.reload();
    }}
    href=""
  >
    Обновить страницу
  </Action>
);

const Action: FunctionComponent<{
  [x: string]: any;
}> = ({onClick, children, ...restOfProps}) => (
  <a className="ouch__link" onClick={onClick} href="" {...restOfProps}>
    {children}
  </a>
);

interface OuchType extends FunctionComponent<OwnProps> {
  Action: typeof Action;
  DefaultAction: typeof DefaultAction;
}

const Ouch: OuchType = ({title, caption, children, ...restOfProps}) => {
  return (
    <div className="ouch" {...restOfProps} data-shmid="ouch">
      <Helmet>
        <style>{css`
          @font-face {
            font-family: Stratos;
            src: url('/static/fonts/Stratos-Regular.woff2') format('woff2'),
              url('/static/fonts/Stratos-Regular.woff') format('woff');
          }

          @font-face {
            font-family: Circe;
            font-style: normal;
            font-weight: normal;
            src: url('/static/fonts/Circe-Regular.woff2') format('woff2'),
              url('/static/fonts/Circe-Regular.woff') format('woff');
          }

          :root {
            --font-regular: Circe, Helvetica Neue, Helvetica, Arial, sans-serif;
            --font-accent: Stratos;
            --ruki-black: #000;
            --ruki-white: #fff;
            --ruki-lightgrey: #f2f2f2;
            --ruki-blue: #00f;
            --ruki-cyan: #00e6e6;
          }

          body {
            margin: 0;
          }

          #root {
            width: 100%;
            font-family: var(--font-regular);
            font-size: 20px;
          }

          .ouch {
            position: fixed;
            overflow: hidden;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            height: 100vh;
            background: var(--ruki-lightgrey);
          }

          .ouch__link {
            text-decoration: none;
            color: var(--ruki-blue);
          }

          .ouch__content {
            display: flex;
            max-width: 1200px;
            height: calc(100vh - 50px);
            box-sizing: border-box;
          }

          @media (max-width: 900px) {
            .ouch__content {
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              padding: 20px 20px 0;
              text-align: center;
            }
          }

          @media (min-width: 901px) {
            .ouch__content {
              height: calc(100vh - 80px);
              justify-content: space-around;
              padding: 45px 155px 0;
            }
          }

          .ouch__left,
          .ouch__right {
            flex: 1 1;
          }

          @media (max-width: 900px) {
            .ouch__left,
            .ouch__right {
              flex-basis: 100%;
            }
          }

          .ouch__right {
            display: flex;
            align-items: flex-end;
            align-self: flex-end;
            justify-content: center;
          }

          @media (max-width: 900px) {
            .ouch__hand {
              transform: scale(0.8);
            }
          }

          @media (min-height: 1366px) {
            .ouch__hand {
              transform: scale(1.6);
            }
          }

          @media (max-width: 440px) {
            .ouch__hand {
              display: none;
            }
          }

          .ouch__title {
            font-family: var(--font-accent);
            font-size: 200px;
          }

          @media (min-width: 901px) {
            .ouch__title {
              margin: 5px 0 -9px -11px;
            }
          }

          .ouch-navbar {
            height: 50px;
            background: var(--ruki-black);
          }

          .ouch-navbar__wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .ouch-navbar__logo {
            margin-right: 10px;
            padding: 0;
            height: 50px;
            width: 50px;
            background: var(--ruki-cyan);
          }

          .ouch-navbar__content {
            max-width: 1200px;
          }

          .ouch-navbar__title {
            height: 37px;
            width: 95px;
          }
        `}</style>
      </Helmet>

      <nav className="ouch-navbar">
        <div className="ouch-navbar__content">
          <a href="/" className="ouch-navbar__wrapper">
            <div className="ouch-navbar__logo">
              <svg
                width="50"
                height="50"
                viewBox="0 0 60 60"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M47.334 43.782h-2.001V54.45h2.001v-2.667H54v-8.001h-6.666zm4.665 6.336h-4.665V45.45h4.665v4.668zM9.666 10.317v10.416l6.333 5.049-5.331 28.668h14.331l1.335-2.883-1.758-4.833 3.423-16.95h8.667l2.667-8.334 6-.999V7.449L16.665 5.451zM46.668 19.449l6.666-2.331v-6.669l-6.666-2.331zM56.406 15.783h1.332l.858-3.999h-1.335zM56.262 11.784h-1.596v3.999h.738zM59.595 11.784l-.858 3.999H60v-3.999z" />
                <path
                  fill="var(--ruki-white)"
                  d="M15.999 25.782l-4.029-3.213-2.637 10.548H0v16.332h28.86l.696-4.05-3.237-1.107 3.618-1.107.747-4.353-2.94-1.176 3.372-1.35.69-4.011-3.252-1.467h9.57l1.209-5.046z"
                />
              </svg>
            </div>

            <div className="ouch-navbar__title">
              <svg
                height="37px"
                width="95px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="var(--ruki-white)"
                  d="M90.487,28.464 L90.487,13.217 L90.269,13.217 L81.255,28.464 L74.495,28.464 L74.495,8.531 L79.022,8.531 L79.022,23.779 L79.219,23.779 L88.253,8.531 L95.014,8.531 L95.014,28.464 L90.487,28.464 ZM65.735,28.464 L55.575,18.657 L55.575,28.464 L51.048,28.464 L51.048,8.531 L55.575,8.531 L55.575,18.140 L65.122,8.531 L70.677,8.531 L60.872,18.339 L71.408,28.464 L65.735,28.464 ZM35.832,35.076 C34.547,36.366 32.985,37.001 31.107,37.001 C30.237,37.001 29.130,36.843 28.636,36.684 L28.241,36.604 L28.241,32.455 C28.913,32.713 29.743,32.852 30.712,32.852 C32.352,32.852 33.598,31.800 34.428,29.675 L34.586,29.278 L25.197,8.531 L30.059,8.531 L36.780,23.699 L36.978,23.699 L42.968,8.531 L47.870,8.531 L39.172,29.675 C38.223,31.978 37.116,33.785 35.832,35.076 ZM5.128,20.465 L5.128,25.751 L5.146,25.751 L5.146,28.464 L0.125,28.464 L0.125,-0.006 L5.128,-0.006 L5.128,-0.000 L23.075,-0.000 L23.075,0.407 L23.075,4.201 L23.075,16.264 L23.075,20.465 L18.082,20.465 L5.128,20.465 ZM18.082,4.201 L5.128,4.201 L5.128,16.264 L18.082,16.264 L18.082,4.201 Z"
                />
              </svg>
            </div>
          </a>
        </div>
      </nav>

      <div className="ouch__content">
        <div className="ouch__left">
          <div className="ouch__title">{title || 'Ой!'}</div>

          <div className="ouch__caption">
            {caption || <>Что-то пошло не&nbsp;так</>}
          </div>

          {children || <DefaultAction />}
        </div>

        <div className="ouch__right">
          <svg
            className="ouch__hand"
            xmlns="http://www.w3.org/2000/svg"
            width="356"
            height="576"
            fill="none"
          >
            <g clipPath="url(#clip0)">
              <path
                fill="var(--ruki-white)"
                d="M257.9 45.5l-32.3 34.8 7.1 3.3 48.7-27.7-23.5-10.4zM225.2 104.3l19.5 37.8 22.1-11.9-34.2-28-7.4 2.1zM232.5 86.5l-7.4-2.9v17.7l7.4-2V86.5zM200.3 55.1L167.7 37v28.7l32.6-10.6z"
              />
              <path
                fill="var(--ruki-blue)"
                d="M219.7 48.8V0l-46.8 7.8-.6 28.4 31.9 17.7 15.5-5.1z"
              />
              <path
                fill="var(--ruki-white)"
                d="M222.1 136.5v-32.4l-54.4-30.8v24.5l54.4 38.7zM198.2 87.1l23.9-3.5V51.2l-54.4 17.6v1l30.5 17.3zM222.1 100.6v-14l-19.6 2.9 19.6 11.1z"
              />
              <path
                fill="var(--ruki-blue)"
                d="M334.2 263.6l-114.5 41.5V138.5l-49.5-35.3-3.5 96-15.9-36.8-34.2 9.7-12.8 38-11.7-31.4-38.9 10.8-12 33.8-8.9-28.3L0 203.9l16.3 193.4v213.2h153.1v-214L356 300.4l-21.8-36.8z"
              />
            </g>

            <defs>
              <clipPath id="clip0">
                <path fill="var(--ruki-white)" d="M0 0h356v610H0z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

Ouch.Action = Action;
Ouch.DefaultAction = DefaultAction;

export default Ouch;
