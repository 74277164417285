/* eslint-disable max-classes-per-file */
import {isApolloError} from '@apollo/client';

export class Gone extends Error {
  name = 'Gone';
}

export class NotFound extends Error {
  name = 'NotFound';
}

export function isGone(e: Error | undefined) {
  return e instanceof Gone;
}

export function isNotFound(e: Error | undefined) {
  return e instanceof NotFound;
}

export function isChunkError(e: Error) {
  const message = e?.message || '';
  return ['Loading', 'chunk', 'failed'].every(word => message.includes(word));
}

export function isNetworkError(e: Error) {
  return /NetworkError/.test(e?.message || '');
}

export function isGraphQLError(e: Error) {
  return isApolloError(e);
}
