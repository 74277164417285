import React, {FunctionComponent} from 'react';
import Ouch from 'client/components/Ouch';
import typographize from '@pollex/utils/typographize';
import {Helmet} from 'react-helmet';

const DEFAULT_TITLE = typographize('Страница не найдена');

interface OwnProps {
  title?: string;
}

const Page404: FunctionComponent<OwnProps> = ({title = DEFAULT_TITLE}) => {
  return (
    <Ouch id="page-404" title="404" caption={title}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Ouch.Action href="/">Главная страница</Ouch.Action>
    </Ouch>
  );
};

export default Page404;
