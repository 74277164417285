type StorageListen = (e: StorageEvent) => void;

type StorageUnlisten = () => void;

export const FALLBACK_STORAGE = {};

export function listen(fn: StorageListen): StorageUnlisten {
  window.addEventListener('storage', fn);

  return () => {
    window.removeEventListener('storage', fn);
  };
}

export function set(key: string, value: any): void {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    //
  }

  FALLBACK_STORAGE[key] = value;
}

export function get(key: string): any {
  try {
    const value = getRaw(key);

    if (value) return JSON.parse(value);
    return undefined;
  } catch (e) {
    return FALLBACK_STORAGE[key];
  }
}

export function getRaw(key: string): string | null {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    return FALLBACK_STORAGE[key];
  }
}

export function remove(key: string): void {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    //
  }
  delete FALLBACK_STORAGE[key];
}
