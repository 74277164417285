/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import isBrowser from '@pollex/utils/is-browser';

import {isProd} from 'client/utils/if-dev-else';
import {catalogItemFromLocation} from 'client/contexts/Location/utils';
import {CategorySlugType, ServiceSlugType} from 'client/types/slug';
import {OrderHash} from 'client/types/id';
import handsRuGetOrder from 'queries/handsRuGetOrder.graphql';
import {EmptyObject} from 'client/types/empty-object';
import GA from './GA';
import {
  TrackerAPI,
  TrackerAPIContext,
  EventKey,
  EventValue,
} from './TrackerAPI';

type GenericPushParams<T> = {
  event: string;
} & T;

interface ECommerceServiceType {
  item_name: string;
  item_id: ServiceSlugType;
  price: number;
  item_category: CategorySlugType;
}

interface CategoryPushParams {
  // eslint-disable-next-line camelcase
  category_id: CategorySlugType;
}

interface ProductPushParams {
  value: number;
  currency: 'RUB';
  items: ECommerceServiceType[];
}

interface SuccessPushParams {
  transaction_id: OrderHash;
  value: number;
  currency: 'RUB';
  items: ECommerceServiceType[];
}

interface CartPushParams {
  value: number;
  currency: 'RUB';
  items: ECommerceServiceType[];
}

type PushParams =
  | GenericPushParams<EmptyObject>
  | GenericPushParams<CategoryPushParams>
  | GenericPushParams<SuccessPushParams>
  | GenericPushParams<ProductPushParams>
  | GenericPushParams<CartPushParams>;

export default class DataLayer implements TrackerAPI {
  static async calculateRevenue(
    hash: OrderHash,
    context: TrackerAPIContext,
  ): Promise<{
    products?: ECommerceServiceType[];
    order?: {
      [x: string]: any;
    };
    revenue?: number;
  }> {
    if (!hash) return {};
    try {
      const {
        data: {
          order,
          order: {wizard, service},
        },
      } = await context.apolloClient.query({
        query: handsRuGetOrder,
        variables: {
          hash,
        },
      });

      const revenue = wizard
        ? wizard.summary.totalPrice.amount
        : service.totalPrice.amount;

      const products = [
        {
          item_id: service.slug,
          item_name: service.name,
          price: revenue,
          item_category: context.theme.name,
          quantity: 1,
        },
      ];

      return {order, revenue, products};
    } catch (e) {
      return {};
    }
  }

  async handleEvent(
    key: EventKey,
    value: EventValue,
    context: TrackerAPIContext,
  ): Promise<void> {
    if (key === 'order_submit') {
      const {order, revenue, products} = await DataLayer.calculateRevenue(
        value.hash,
        context,
      );

      if (!(order && revenue && products)) return;

      const params: GenericPushParams<SuccessPushParams> = {
        event: 'purchase',
        transaction_id: order.hash,
        value: revenue,
        currency: 'RUB',
        items: products,
      };

      this.push(params);
      return;
    }

    if (!['quick_view', 'first_interaction'].includes(key)) return;

    const {screenName} = context.location;

    const catalogItem = catalogItemFromLocation(context.location.location);

    const baseParams = {
      event: key,
    };

    const slug: ServiceSlugType = value.slug as any;

    if (catalogItem?.dataType === 'SERVICE') {
      const {price, name} = value;

      if (!name) return;

      const params: GenericPushParams<ProductPushParams> = {
        event: 'view_item',
        currency: 'RUB',
        value: price,
        items: [
          {
            item_id: slug,
            item_name: String(name),
            price: Number(price),
            item_category: context.theme.name,
          },
        ],
      };

      this.push(params);
      return;
    }

    if (screenName === 'service') {
      const {price, name} = value;

      const products = [
        {
          item_id: slug,
          item_name: String(name),
          price: Number(price),
          item_category: context.theme.name,
          quantity: 1,
        },
      ];

      const params: GenericPushParams<CartPushParams> = {
        event: 'add_to_cart',
        currency: 'RUB',
        value: price,
        items: products,
      };

      this.push(params);
      return;
    }

    // @ts-ignore
    if (window.dataLayer?.some(e => e[2] && !!e[2].transaction_id)) {
      return;
    }

    this.push(baseParams as GenericPushParams<EmptyObject>);
  }

  push(params?: PushParams): void {
    if (!isBrowser() || !params) return;
    if (!isProd()) {
      this.logDevelopmentData(params);
    }
    const {event, ...rest} = params;

    GA.gtag('event', event, rest);
  }

  logDevelopmentData(params: {[x: string]: any}): void {
    console.group('DataLayer change');
    console.table(params);
    console.groupEnd();
  }
}
