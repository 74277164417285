import url from 'url';
import pickBy from 'lodash/pickBy';
import qs from 'qs';
import canonicalWithTrailingSlash, {
  canonicalWithoutTrailingSlash,
} from 'client/utils/canonical';
import {LocationBag, LocationQueryType} from 'client/contexts/Location';

export interface URLSerializationOptions {
  isCanonical?: boolean;
  preserveQuery?: boolean;
}

export interface SerializableURLParams {
  base: string;
  query?: {
    [x: string]: any;
  };
  hash?: string;
  host?: string;
}

export default class SerializableURL {
  base!: string;

  query!: {[x: string]: any};

  hash!: string | void;

  host!: string | undefined;

  isCanonical?: boolean;

  constructor({base, query, hash, host}: SerializableURLParams) {
    Object.assign(this, {base, query, hash});
    if (host) this.host = host;
  }

  serialize(
    location?: LocationBag,
    options: URLSerializationOptions = {},
  ): string {
    const {base, query, hash = '', host} = this;
    const {isCanonical} = options;

    const href = SerializableURL.serialize({
      base,
      hash,
      query: SerializableURL.computeQuery(query, location, options),
    });
    const canonicalHost = host || location?.host || '';

    if (isCanonical) {
      // Canonical URL for main page should be without trailing slash
      if (!base) {
        return canonicalWithoutTrailingSlash(canonicalHost, base);
      }
      return canonicalWithTrailingSlash(canonicalHost, base);
    }

    if (host) {
      return `//${host}${href}`;
    }

    return href;
  }

  static serialize({base, query, hash}: SerializableURLParams): string {
    const {pathname: basePathname, query: baseQuery} = url.parse(base, true);

    const querySearch = qs.stringify({
      ...baseQuery,
      ...pickBy(query, Boolean),
    });
    const pathname = !basePathname && querySearch ? '/' : basePathname;

    return url.format({
      pathname,
      hash,
      search: querySearch,
    });
  }

  static computeQuery(
    newQuery: LocationQueryType,
    location?: LocationBag,
    options: URLSerializationOptions = {},
  ): LocationQueryType {
    const {preserveQuery} = options;

    if (!location) return newQuery;

    if (preserveQuery) {
      return {...location.query, ...newQuery};
    }

    return {
      ...pickBy(
        location.query,
        (_, k: string) => k.startsWith('utm') || k === 'test',
      ),
      ...newQuery,
    };
  }
}
