import React, {ReactNode, FunctionComponent} from 'react';

import cx from 'classnames';
import './CenteredElement.css';

interface OwnProps {
  children: ReactNode;
  extraClassName?: string;
}

const CenteredElement: FunctionComponent<OwnProps> = ({
  children,
  extraClassName,
  ...restOfProps
}: OwnProps) => (
  <div className={cx('centered-element', extraClassName)} {...restOfProps}>
    {children}
  </div>
);

export default CenteredElement;
