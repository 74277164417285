import isBrowser from '@pollex/utils/is-browser';

export const atob =
  isBrowser() && window.atob
    ? window.atob
    : (val: string) => Buffer.from(val, 'base64').toString();

export const btoa =
  isBrowser() && window.btoa
    ? window.btoa
    : (val: string) => Buffer.from(val).toString('base64');
