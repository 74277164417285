import {WithContext, LocalBusiness, Product} from 'schema-dts';
import {HandsRuGetProductStructuredDataData} from 'query-wrappers/HandsRuGetProductStructuredData';

type DataType = 'SERVICE' | 'OBJECT' | 'CATEGORY';

interface PriceType {
  amount: number;
  unitMorphology: string;
}

interface ExtractedProductData {
  name: string;
  description: string;
  price?: PriceType;
  minimumPrice?: PriceType;
}

const SERVICE_PATHS = [
  '/service/:slug/price',
  '/service/:slug/:geo',
  '/service/:slug/',
];

const OBJECT_PATHS = [
  '/object/:slug/',
  '/object/:slug/price',
  '/object/:slug/:geo/',
];

const CATEGORY_PATHS = [
  '/category/:slug/',
  '/category/:slug/geo/',
  '/category/:slug/geo/:geo/',
];

export const PRICE_PATHS = [
  '/service/:slug/price',
  '/object/:slug/price',
  '/category/:slug/price',
];

export const CATALOG_PATHS = [
  ...SERVICE_PATHS,
  ...OBJECT_PATHS,
  ...CATEGORY_PATHS,
];

export function getDataTypeFromPath(path?: string): DataType {
  if (!path) return 'SERVICE';

  if (CATEGORY_PATHS.includes(path)) {
    return 'CATEGORY';
  }

  if (OBJECT_PATHS.includes(path)) {
    return 'OBJECT';
  }

  return 'SERVICE';
}

export function getPageTypeFromPath(path?: string): 'PRICELIST' | 'MAIN' {
  return PRICE_PATHS.includes(path || '') ? 'PRICELIST' : 'MAIN';
}

export function getDataFromPage(
  type: DataType,
  data?: HandsRuGetProductStructuredDataData,
): ExtractedProductData | null {
  if (!data) return null;

  if (type === 'SERVICE' && data.catalog.servicePage) {
    const {name, metaDescription, service} = data.catalog.servicePage;
    return {
      name,
      description: metaDescription,
      price: service.price,
      minimumPrice: service.minimumPrice,
    };
  }

  if (type === 'OBJECT' && data.catalog.objectPage) {
    const {name, metaDescription} = data.catalog.objectPage;
    return {
      name,
      description: metaDescription,
    };
  }

  if (type === 'CATEGORY' && data.catalog.categoryPage) {
    const {name, metaDescription} = data.catalog.categoryPage;
    return {
      name,
      description: metaDescription,
    };
  }

  return null;
}

export function isLocalBusinessData<Subtype = WithContext<LocalBusiness>>(
  data: any,
): data is Subtype {
  if (!data) return false;
  return data['@type'] === 'LocalBusiness';
}

export function isProductData<Subtype = WithContext<Product>>(
  data: any,
): data is Subtype {
  if (!data) return false;
  return data['@type'] === 'Product';
}
