import React, {FunctionComponent} from 'react';

import {useMeta} from 'client/contexts/Meta';
import Analytics from 'client/contexts/Analytics';
import {Helmet} from 'react-helmet';
import PageChangeHandler from 'client/components/PageChangeHandler';
import {Switch, Route} from 'react-router-dom';
import {ROUTES} from 'client/routes';
import LocalBusinessOrProductStructuredData from 'client/components/StructuredData/LocalBusinessOrProduct';
import {NotFound} from 'client/utils/errors';
import {getEnv} from 'client/utils/env';

import './App.scss';

export const App: FunctionComponent = () => {
  const {
    siteTitle,
    metaDescription,
    ogImage,
    ogDescription,
    ogUrl,
    isMobile,
  } = useMeta();
  const version = getEnv().VERSION;

  return (
    <Analytics.Provider>
      <PageChangeHandler>
        <Helmet>
          <title>{siteTitle}</title>
          <meta httpEquiv="Cache-control" content="must-revalidate" />
          <meta httpEquiv="ETag" content={version} />
          <meta name="description" content={metaDescription} />
          <meta name="twitter:image:src" content={ogImage} />
          <meta property="og:url" content={ogUrl} />
          <meta property="og:image" content={ogImage} />
          <meta property="og:title" content={siteTitle} />
          <meta property="og:description" content={ogDescription} />
        </Helmet>

        <Switch>
          {ROUTES}

          <Route
            path="*"
            render={() => {
              throw new NotFound('Page not found');
            }}
          />
        </Switch>
      </PageChangeHandler>

      <LocalBusinessOrProductStructuredData
        kind={isMobile ? 'LocalBusiness' : 'Product'}
      />
    </Analytics.Provider>
  );
};

export default App;
