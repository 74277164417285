import handsRuAddCartItem from 'queries/handsRuAddCartItem_2.graphql';
import {CartType} from 'client/types/client-cart';
import {HandsRuMutateWizardVariables} from './HandsRuMutateWizard';

export interface HandsRuAddCartItemData {
  addCartItem: {
    cart: CartType;
  };
}

export interface HandsRuAddCartItemVariables {
  serviceSlug: string;
  input?: HandsRuMutateWizardVariables['input'];
}

export default handsRuAddCartItem;
