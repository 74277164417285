import apiAuth from 'client/utils/api-auth';
import isomorphicFetch from 'isomorphic-fetch';

export default async function fetch(
  input: URL | RequestInfo,
  init: RequestInit = {headers: {}},
): Promise<Response> {
  const {headers, ...restOfOptions} = init;
  const newOptions = {
    ...restOfOptions,
    headers: {
      ...headers,
      ...apiAuth(),
    },
  };

  return isomorphicFetch(input, newOptions);
}
