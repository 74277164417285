import React, {useContext, useCallback, FunctionComponent} from 'react';
import noop from 'lodash/noop';
import {useQuery, useApolloClient} from '@apollo/client';
import keyBy from 'lodash/keyBy';
import handsRuGetCities, {
  HandsRuGetCityData,
} from 'query-wrappers/HandsRuGetCities';
import {CityType} from 'client/types/city';
import {
  CityBusinessComponentType,
  BusinessComponentEnum,
} from 'client/types/business-component';
import handsRuTryCatalogItemForCity, {
  HandsRuTryCatalogItemForCityData,
  HandsRuTryCatalogItemForCityVariables,
} from 'query-wrappers/HandsRuTryCatalogItemForCity';
import SerializableURL from 'client/utils/serializable-url';
import {dataTypeToPage} from 'client/utils/catalog-page';
import {useLocation, catalogItemFromLocation} from '../Location';
import {removeGeoFromPath} from './utils';
import {geoFromLocation} from '../Location/utils';

export * from 'client/types/business-component';

interface OwnProps {
  thirdLevelDomain: string;
}

export interface CityBag {
  current: CityType;
  available: Map<string, CityType>;
  changeDomain: (item: {thirdLevelDomain: string}) => void;
  raw: CityType[];
}

export const DEFAULT_BAG: CityBag = {
  available: new Map<string, CityType>(),

  current: {
    id: '',
    thirdLevelDomain: '',
    host: 'hands.ru',
    prepositional: 'в Москве',
    shortname: 'МСК',
    name: 'Москва',
    components: [],
    stats: {
      activeSpecialistCount: 0,
    },
  },

  raw: [],
  changeDomain: noop,
};

const Context = React.createContext<CityBag>(DEFAULT_BAG);

function getCityMap(cities: CityType[]): Map<string, CityType> {
  const map = new Map();

  for (const city of cities) {
    map.set(city.thirdLevelDomain, city);
  }

  return map;
}

const CityProvider: FunctionComponent<OwnProps> = ({
  children,
  thirdLevelDomain,
}) => {
  const location = useLocation();
  const {data, error} = useQuery<HandsRuGetCityData>(handsRuGetCities);
  const cities = data?.cities || [];
  const available = getCityMap(cities);
  const current = available.get(thirdLevelDomain) || DEFAULT_BAG.current;
  const catalogItem = catalogItemFromLocation(location.location);
  const apolloClient = useApolloClient();

  const changeDomain = useCallback(
    async (options: {thirdLevelDomain: string}) => {
      const newCity = available.get(options.thirdLevelDomain);
      const newHost = newCity?.host;

      if (!newHost || newCity?.thirdLevelDomain === current.thirdLevelDomain) {
        return;
      }

      if (catalogItem && newCity) {
        const {slug, dataType} = catalogItem;
        const key = dataTypeToPage(dataType);
        const {
          data: {
            catalog: {
              [key]: {
                item: {isVisibleForCity},
              },
            },
          },
        } = await apolloClient.query<
          HandsRuTryCatalogItemForCityData<typeof key>,
          HandsRuTryCatalogItemForCityVariables
        >({
          query: handsRuTryCatalogItemForCity,
          variables: {
            slug,
            cityId: newCity.id,
            isServicePage: dataType === 'SERVICE',
            isObjectPage: dataType === 'OBJECT',
            isCategoryPage: dataType === 'CATEGORY',
          },
        });

        if (!isVisibleForCity) {
          window.location.assign(
            new SerializableURL({
              host: newHost,
              base: '/',
            }).serialize(location, {preserveQuery: true}),
          );
        }
      }

      const {pathname} = location.location;
      const base = removeGeoFromPath(pathname, geoFromLocation(pathname));

      window.location.replace(
        new SerializableURL({
          host: newHost,
          base,
        }).serialize(location, {preserveQuery: true}),
      );
    },
    [available],
  );

  if (error) throw error;

  return (
    <Context.Provider value={{available, current, raw: cities, changeDomain}}>
      {children}
    </Context.Provider>
  );
};

export function useBusinessComponents<
  Types extends CityBusinessComponentType[]
>(names: BusinessComponentEnum[]): Types {
  const {current} = useContext(Context);
  if (!current) return ([] as any) as Types;

  const components = keyBy(current.components, ({name}) => name);
  return names.map(name => components[name]) as Types;
}

export const useCity = (): CityBag => {
  return useContext(Context);
};

export default {
  Context,
  Provider: CityProvider,
  Consumer: Context.Consumer,
};
