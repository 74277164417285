import React from 'react';
import baseLoadable from '@loadable/component';
import LoadableFallback from 'client/components/LoadableFallback';

const loadable: typeof baseLoadable = (fn, options) =>
  baseLoadable(fn, {...options, fallback: <LoadableFallback />});

loadable.lib = baseLoadable.lib;

export default loadable;
