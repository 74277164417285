import handsRuGetAuthorizedClient from 'queries/handsRuGetAuthorizedClient.graphql';

export interface HandsRuGetAuthorizedClientData {
  authorizedClient: {
    name: string;
    phone: string;
    email: string;
  };
}

export default handsRuGetAuthorizedClient;
