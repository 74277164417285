import {useEffect} from 'react';
import Intercom from 'client/contexts/Analytics/trackers/Intercom';

const useIntercomOnShowEvent = (callback: () => void) => {
  useEffect(() => {
    if (Intercom.isAllowed()) {
      const interval = setInterval(() => {
        if (Intercom.isPresent()) {
          callback();
          clearInterval(interval);
        }
      }, 1000);
    }
  }, []);
  return null;
};

export default useIntercomOnShowEvent;
