import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import './CircularProgress.css';

interface OwnProps {
  extraClassName?: string;
  value?: number;
  color?: string;
  size?: '14px' | '20px' | '30px' | '40px';
}

const CircularProgress: FunctionComponent<OwnProps> = ({
  extraClassName,
  size = '20px',
  color,
  ...restOfProps
}) => (
  <>
    &nbsp;
    <div
      style={{borderColor: color}}
      data-shmid="progress"
      className={cx(
        'circular-progress',
        `circular-progress_size_${size}`,
        extraClassName,
      )}
      role="progressbar"
      {...restOfProps}
    />
  </>
);

export default CircularProgress;
