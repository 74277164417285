/* eslint no-console: off, camelcase: off */
import isBrowser from '@pollex/utils/is-browser';
import {safeWrap} from 'client/utils/safe';

import {ScreenName} from 'client/contexts/Location';
import {getEnv} from 'client/utils/env';

interface IntercomSettings {
  horizontal_padding: number;
  vertical_padding: number;
}

class Intercom {
  static APP_ID = getEnv().HANDS_RU_INTERCOM_APP_ID;

  static DEFAULT_OPTIONS: IntercomSettings = {
    horizontal_padding: 20,
    vertical_padding: 30,
  };

  static BLACKLISTED_PAGES: ScreenName[] = [];

  static isAllowed(): boolean {
    return !!getEnv().HANDS_RU_INTERCOM_APP_ID;
  }

  static isPresent(): boolean {
    // @ts-ignore
    return isBrowser() && !!window.Intercom;
  }

  static run(...args: any): void {
    // @ts-ignore
    if (typeof window.Intercom === 'function') {
      // @ts-ignore
      return window.Intercom(...args);
    }
  }

  static hide = safeWrap(() => {
    if (!Intercom.isAllowed()) return;
    Intercom.run('update', {hide_default_launcher: true});
  });

  static show = safeWrap(() => {
    if (!Intercom.isAllowed()) return;
    Intercom.run('update', {hide_default_launcher: false});
  });
}

export default Intercom;
