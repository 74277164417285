import {matchPath} from 'react-router-dom';
import {emphasize, hexToRGB} from '@pollex/utils/colors';
import {LocationBag} from 'client/contexts/Location';
import {CategorySlugType} from 'client/types/slug';
import {HandsRuGetCategoryData} from 'query-wrappers/HandsRuGetCategories';
import {DEFAULT_BAG} from './constants';

import {ThemeCacheType, ThemeType} from './types';

type MaybeSlug = CategorySlugType | undefined | null;

export function themesToCache(
  categories: HandsRuGetCategoryData['categories'],
): [string, ThemeType][] {
  return categories.map(
    ({
      slug,
      images: [image],
      covers: [cover],
      alternativeImages: [alternativeImage],
      icons: [icon],
      ...restOfProps
    }) => [
      slug,
      {
        slug,
        image,
        cover,
        alternativeImage,
        icon,
        ...restOfProps,
      },
    ],
  );
}

export function themeSlugForExcludedPages(
  _: ThemeCacheType,
  {location: {pathname}}: LocationBag,
): MaybeSlug {
  if (matchPath(pathname, {path: '/specialist/documents'})) {
    return 'specialist-documents';
  }

  const match =
    matchPath(pathname, {path: '/', exact: true}) ||
    matchPath(pathname, {path: '/cart/'}) ||
    matchPath(pathname, {path: '/success/'}) ||
    matchPath(pathname, {path: '/catalog/', exact: true}) ||
    matchPath(pathname, {path: '/documents/'}) ||
    matchPath(pathname, {path: '/vacancies/'}) ||
    matchPath(pathname, {path: '/specialist/', exact: true}) ||
    matchPath(pathname, {path: '/specialist/success/', exact: true}) ||
    matchPath(pathname, {path: '/client/orders/', exact: true}) ||
    matchPath(pathname, {path: '/company/'});

  if (match) {
    return 'ruki';
  }

  return null;
}
export function themeSlugForCategoryPages(
  cache: ThemeCacheType,
  {location}: LocationBag,
): MaybeSlug {
  const match = matchPath<{slug: CategorySlugType}>(location.pathname, {
    path: '/category/:slug/',
  });

  const slug = match?.params?.slug;

  if (slug && cache.has(slug)) {
    return slug;
  }

  return null;
}

export function themeSlugForThemedPages(
  cache: ThemeCacheType,
  {query}: LocationBag,
): MaybeSlug {
  if (cache.has(query?.category)) {
    return query.category;
  }

  return null;
}

export function themeSlugFromURLParts(
  cache: ThemeCacheType,
  location: LocationBag,
): MaybeSlug {
  return (
    themeSlugForExcludedPages(cache, location) ||
    themeSlugForCategoryPages(cache, location) ||
    themeSlugForThemedPages(cache, location)
  );
}
// @ts-ignore
const e = (...args: any[]): string => emphasize(...args).join(',');
// @ts-ignore
const rgb = (...args: any[]): string => hexToRGB(...args).join(',');

export function variablesFromTheme({
  defaultBackground = DEFAULT_BAG.defaultBackground,
  defaultText = DEFAULT_BAG.defaultText,
  accentBackground = DEFAULT_BAG.accentBackground,
  accentText = DEFAULT_BAG.accentText,
}: ThemeType): [string, string][] {
  return [
    ['--theme-default-background', defaultBackground],
    ['--theme-default-text', defaultText],
    ['--theme-accent-background', accentBackground],
    ['--theme-accent-text', accentText],
    ['--theme-accent-background-hover', `rgb(${e(accentBackground, 0.1)})`],
    ['--theme-accent-background-active', `rgb(${e(accentBackground, 0.2)})`],
    ['--theme-default-background-hover', `rgb(${e(defaultBackground, 0.1)})`],
    ['--theme-default-background-active', `rgb(${e(defaultBackground, 0.2)})`],
    ['--theme-default-text-hover', `rgb(${e(defaultText, 0.1)})`],
    ['--theme-default-text-active', `rgb(${e(defaultText, 0.2)})`],
    ['--theme-accent-text-active', `rgb(${e(accentText, 0.2)})`],
    ['--theme-accent-text-shadow', `rgba(${rgb(accentText)}, 0.1)`],
  ];
}
