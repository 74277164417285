import SerializableURL from 'client/utils/serializable-url';
import pluckSlugsFromQuery from 'client/utils/pluck-slugs-from-query';
import {Sluggable} from 'client/types/slug';
import {CatalogDataTypeEnum} from 'client/types/page-type';
import {SpecialistDocumentFormStep} from 'client/types/specialist';
import {SupplementId} from 'client/types/id';
import pickBy from 'lodash/pickBy';
import {FormStepType} from 'client/pages/PageSpecialistVacancyForm/utils';
import {getEnv} from './env';

export function toMain(): SerializableURL {
  return new SerializableURL({base: ''});
}

export function toDocuments(slug?: string): SerializableURL {
  if (slug) {
    return new SerializableURL({base: `/documents/${slug}/`});
  }
  return new SerializableURL({base: '/documents/'});
}

export function toGuarantee(): SerializableURL {
  return new SerializableURL({base: '/documents/guarantee/'});
}

export function toVacancies(): SerializableURL {
  return new SerializableURL({base: '/vacancies/'});
}

export function toTermsOfUse(): SerializableURL {
  return new SerializableURL({base: '/documents/terms-of-use/'});
}

export function toTermsOfServiceUse(): SerializableURL {
  return new SerializableURL({base: '/documents/terms-of-use-service-ruki/'});
}

export function toPrivacyPolicy(): SerializableURL {
  return new SerializableURL({base: '/documents/privacy-policy/'});
}

export function toOffer(): SerializableURL {
  return new SerializableURL({base: '/documents/master-oferta/'});
}

export function toBackoffice(): SerializableURL {
  return new SerializableURL({base: '/backoffice/'});
}

export function toCompanyAbout(): SerializableURL {
  return new SerializableURL({base: '/company/about/'});
}

export function toCompanyContacts(): SerializableURL {
  return new SerializableURL({base: '/company/contact-us/'});
}

export function toClassifier(): SerializableURL {
  return new SerializableURL({base: '/category/'});
}

export function toRetail(): SerializableURL {
  return new SerializableURL({base: '/', host: 'retail.hands.ru'});
}

export function toErrorForwarder(): SerializableURL {
  return new SerializableURL({base: '/error/'});
}

export function toCompensation(): SerializableURL {
  return new SerializableURL({base: '/compensation/'});
}

export function toStat(): SerializableURL {
  return new SerializableURL({
    base: '/v1/collect/web/',
    host: getEnv().HANDS_RU_STAT_HOST,
  });
}

export function toTgDeeplink(): SerializableURL {
  return new SerializableURL({
    base: '/api/v1/tg_deeplink/',
    host: 'api.hands.ru',
  });
}

export function toSpecialistRegistration(
  specialization?: Sluggable<any>,
): SerializableURL {
  const slugs = pluckSlugsFromQuery({specialization});
  const base = '/specialist/';

  if (!slugs.specialization) return new SerializableURL({base});
  return new SerializableURL({
    base: `${base}${slugs.specialization}/`,
  });
}

export function toSpecialistVacancyForm(
  specialization: Sluggable<any>,
  step: FormStepType,
): SerializableURL {
  const slugs = pluckSlugsFromQuery({specialization});
  const base = '/specialist/';

  if (!slugs.specialization) return new SerializableURL({base});
  return new SerializableURL({
    base: `${base}${slugs.specialization}/`,
    query: {
      step,
    },
  });
}

export function toSpecialistDocuments(
  login: string,
  hash: string,
  step?: SpecialistDocumentFormStep,
): SerializableURL {
  const query = {login, hash};
  const base = `/specialist/documents/`;

  if (step) {
    return new SerializableURL({
      base: `${base}${step}/`,
      query,
    });
  }

  return new SerializableURL({
    base,
    query,
  });
}

export function toSpecialistWorkConditions(): SerializableURL {
  return new SerializableURL({
    base: '/registration',
    host: 'registrationhands.tilda.ws',
  });
}

export function toSpecialistRegistrationForm(
  specialization?: Sluggable<any>,
): SerializableURL {
  const slugs = pluckSlugsFromQuery({specialization});

  if (!slugs.specialization) {
    return new SerializableURL({base: '/specialist/register/'});
  }

  return new SerializableURL({
    base: `/specialist/${slugs.specialization}/register/`,
  });
}

export function toCategory(
  category: Sluggable<any>,
  geoUnit?: Sluggable<any>,
): SerializableURL {
  const slugs = pluckSlugsFromQuery({category, geoUnit});

  if (!slugs.category) return toClassifier();

  const base = `/category/${slugs.category}/`;

  if (!slugs.geoUnit) {
    return new SerializableURL({base});
  }

  return new SerializableURL({
    base: `${base}geo/${slugs.geoUnit}/`,
  });
}

export function toObject(
  object: Sluggable<any>,
  category?: Sluggable<any>,
): SerializableURL {
  const slugs = pluckSlugsFromQuery({object, category});

  const base = `/object/${slugs.object}`;

  return new SerializableURL({
    base: `${base}${slugs.geoUnit}/`,
    query: {
      category: slugs.category,
    },
  });
}

export function toService(
  service: Sluggable<any>,
  options: {
    category?: Sluggable<any>;
    geoUnit?: Sluggable<any>;
  } = {},
): SerializableURL {
  const {geoUnit, category} = options;
  const slugs = pluckSlugsFromQuery({service, category, geoUnit});

  const base = slugs.geoUnit
    ? `/service/${slugs.service}/${slugs.geoUnit}/`.replace('//', '/')
    : `/service/${slugs.service}/`;

  return new SerializableURL({
    base,
    query: {
      category: slugs.category,
    },
  });
}

export function toCalculator(
  service: Sluggable<any>,
  quantities?: Record<SupplementId, number>,
): SerializableURL {
  const {base} = toService(service);
  return new SerializableURL({
    base: `${base}smeta/`,
    query: {s: pickBy(quantities, Boolean)},
  });
}

export function toPriceList(
  slug: string,
  type = 'service',
  quantities?: Record<SupplementId, number>,
): SerializableURL {
  const {base} = (() => {
    if (type === 'category') return toCategory({slug}) as SerializableURL;
    if (type === 'object') return toObject({slug}) as SerializableURL;
    if (type === 'service') return toService({slug}) as SerializableURL;
    return null as never;
  })();
  return new SerializableURL({
    base: `${base}price/`,
    query: {s: pickBy(quantities, Boolean)},
  });
}

function getServiceQuery(category?: Sluggable<any>) {
  const slugs = pluckSlugsFromQuery({category});
  const query: Record<string, any> = {};
  if (slugs.category) query.category = slugs.category;
  return query;
}

export function toCartOrderAddress(): SerializableURL {
  return new SerializableURL({
    base: '/client/cart/',
    query: {
      step: 'address',
    },
  });
}

export function toOrderAddress(
  service: Sluggable<any>,
  category?: Sluggable<any>,
): SerializableURL {
  if (!service.slug) {
    return toCartOrderAddress();
  }
  const {base} = toService(service);
  return new SerializableURL({
    base,
    query: {
      step: 'address',
      ...getServiceQuery(category),
    },
  });
}

export function toCartOrderDate(): SerializableURL {
  return new SerializableURL({
    base: '/client/cart/',
    query: {
      step: 'date',
    },
  });
}

export function toOrderDate(
  service: Sluggable<any>,
  category?: Sluggable<any>,
): SerializableURL {
  if (!service.slug) {
    return toCartOrderDate();
  }
  const {base} = toService(service);
  return new SerializableURL({
    base,
    query: {
      step: 'date',
      ...getServiceQuery(category),
    },
  });
}

export function toCartOrderContacts(): SerializableURL {
  return new SerializableURL({
    base: '/client/cart/',
    query: {
      step: 'contacts',
    },
  });
}

export function toOrderContacts(
  service: Sluggable<any>,
  category?: Sluggable<any>,
  geoUnit?: Sluggable<any>,
): SerializableURL {
  if (!service.slug) {
    return toCartOrderContacts();
  }
  const {base} = toService(service, {geoUnit});
  return new SerializableURL({
    base,
    query: {
      step: 'contacts',
      ...getServiceQuery(category),
    },
  });
}

export function toOrderResources(
  service: Sluggable<any>,
  category?: Sluggable<any>,
): SerializableURL {
  const {base} = toService(service);
  return new SerializableURL({
    base,
    query: {
      step: 'resources',
      ...getServiceQuery(category),
    },
  });
}

export function toCartOrderCommentaries(): SerializableURL {
  return new SerializableURL({
    base: '/client/cart/',
    query: {
      step: 'commentaries',
    },
  });
}

export function toOrderCommentaries(
  service: Sluggable<any>,
  category?: Sluggable<any>,
  geoUnit?: Sluggable<any>,
  fromWizard?: boolean,
): SerializableURL {
  const {base} = toService(service, {geoUnit});
  if (!service.slug) {
    return toCartOrderCommentaries();
  }
  return new SerializableURL({
    base,
    query: {
      step: 'commentaries',
      ...getServiceQuery(category),
      ...(fromWizard ? {fromWizard: true} : {}),
    },
  });
}

export function toOrderSummary(
  service: Sluggable<any>,
  category?: Sluggable<any>,
): SerializableURL {
  const {base} = toService(service);

  return new SerializableURL({
    base,
    query: {
      step: 'summary',
      ...getServiceQuery(category),
    },
  });
}

export function toOrderWizard(
  service: Sluggable<any>,
  category?: Sluggable<any>,
): SerializableURL {
  const slugs = pluckSlugsFromQuery({service, category});
  const {base} = toService(service);

  return new SerializableURL({
    base,
    query: {
      step: 'wizard',
      category: slugs.category,
    },
  });
}

export function toNextWizard(
  service: Sluggable<any>,
  nextQuestion: string,
): SerializableURL {
  const {base} = toService(service);

  return new SerializableURL({
    base,
    query: {
      step: 'wizard',
      questionId: nextQuestion,
    },
  });
}

export function toProfilePage(slug: string): SerializableURL {
  return new SerializableURL({
    base: `/profile/${slug}/`,
  });
}

export function toAllReviewPage(
  options: {page?: number} = {},
): SerializableURL {
  const {page} = options;
  const query = {} as Record<string, string>;

  if (page) {
    query.p = String(page);
  }

  return new SerializableURL({base: '/reviews/', query});
}

export function toReviewPage(
  type: CatalogDataTypeEnum,
  slug: string,
  options: {filterType?: string; page?: number; geo?: string} = {},
): SerializableURL {
  const {filterType, page, geo} = options;
  const query = {filterType} as Record<string, string>;
  if (page) {
    query.p = String(page);
  }

  return new SerializableURL({
    base: `/${type.toLowerCase()}/${slug}/reviews/${geo ? `${geo}/` : ''}`,
    query,
  });
}

export function toSpecialistSuccess(login?: string): SerializableURL {
  return new SerializableURL({base: '/specialist/success/', query: {login}});
}

export function toOrderSuccess(
  order: {hash: string},
  withPrepayError?: boolean,
): SerializableURL {
  return new SerializableURL({
    base: '/success/',
    query: {
      order: order.hash,
      ...(withPrepayError ? {prepayError: '1'} : {}),
    },
  });
}

export function toClientOrders(withPrepayError?: boolean): SerializableURL {
  return new SerializableURL({
    base: '/client/orders/',
    query: withPrepayError ? {prepayError: '1'} : {},
  });
}

export function toClientCart(): SerializableURL {
  return new SerializableURL({base: '/client/cart/'});
}

export function toReviewForm(hash: string): SerializableURL {
  return new SerializableURL({base: '/review/add/', query: {hash}});
}
