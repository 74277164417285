import handsRuGetReviewSlice from 'queries/handsRuGetReviewSlice_4.graphql';
import ReviewType, {ReviewFilterType, StatsType} from 'client/types/review';
import {CatalogDataTypeEnum, CatalogPageTypeEnum} from 'client/types/page-type';

export interface HandsRuGetReviewSliceData {
  reviewSlice: {
    entries: ReviewType[];
    totalCount: number;
  };

  stats: StatsType;

  allReviewSliceForCount: {
    totalCount: number;
  };

  positiveReviewSliceForCount: {
    totalCount: number;
  };

  negativeReviewSliceForCount: {
    totalCount: number;
  };

  elaborateReviewSliceForCount: {
    totalCount: number;
  };

  regularCustomerReviewSliceForCount: {
    totalCount: number;
  };

  withPhotoReviewSliceForCount: {
    totalCount: number;
  };
}

export interface HandsRuGetReviewSliceVariables {
  slug: string;
  geo?: string;
  dataType: CatalogDataTypeEnum;
  pageType: CatalogPageTypeEnum;
  filterType: ReviewFilterType;
  perPage: number;
  page: number;
}

export default handsRuGetReviewSlice;
