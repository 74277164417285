import {UnderConstructionNoticeType} from 'client/types/business-component';

export function isCategoryAvailable(
  categorySlug: string,
  notice: UnderConstructionNoticeType,
): boolean {
  if (!notice || !notice.categories) return true;
  return notice.categories.some(c => c.slug === categorySlug);
}

export function removeGeoFromPath(path: string, geo: string | null) {
  if (path === '/') {
    return path;
  }

  const paths = path.split('/').filter(Boolean);

  if (paths[paths.length - 1] === geo) {
    paths.pop();
  }

  return `/${paths.join('/')}/`;
}
