import React, {
  useContext,
  ReactNode,
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react';

import noop from 'lodash/noop';
import * as storage from '@pollex/utils/safe-local-storage';
import normalizeUserInputPhone from '@pollex/utils/normalize-user-input-phone';
import handsRuGetAuthorizedClient, {
  HandsRuGetAuthorizedClientData,
} from 'query-wrappers/HandsRuGetAuthorizedClient';
import {useQuery} from '@apollo/client';
import {IdentifyUser, PersistUser, UserBag, UserStateType} from './types';

export * from './types';

interface OwnProps {
  children: ReactNode;
}

export const DEFAULT_STATE: UserStateType = {
  isAuthenticated: true,
  name: '',
  email: '',
  phone: '+7',
  loading: false,
};

const Context = React.createContext<UserBag>({
  ...DEFAULT_STATE,
  login: noop,
  logout: noop,
  identify: noop,
  persist: noop,
  forget: noop,
});

const UserProvider: FunctionComponent<OwnProps> = ({children}: OwnProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+7');

  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery<HandsRuGetAuthorizedClientData>(handsRuGetAuthorizedClient, {
    ssr: false,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if ('Cypress' in window) {
      logout();
    } else {
      const user = storage.get('user');
      if (user) identify(user);
    }
  }, []);

  useEffect(() => {
    if (data?.authorizedClient) identify(data.authorizedClient);
  }, [data]);

  useEffect(() => {
    if (error?.message === 'AUTH_REQUIRED') logout();
  }, [error]);

  const login = useCallback((): void => {
    setIsAuthenticated(true);
    refetch();
  }, []);

  const logout = useCallback((): void => {
    setIsAuthenticated(false);
  }, []);

  const forget = useCallback(() => {
    setName('');
    setEmail('');
    setPhone('+7');
    setIsAuthenticated(false);
  }, []);

  const identify: IdentifyUser = useCallback(
    ({name: newName, email: newEmail, phone: newPhone}): void => {
      setName(newName || '');
      setEmail(newEmail || '');
      setPhone(newPhone || '+7');
      try {
        persist();
      } catch (e) {
        // no
      }
    },
    [],
  );

  const persist: PersistUser = useCallback((): void => {
    storage.set('user', {name, email, phone});
  }, [name, email, phone]);

  return (
    <Context.Provider
      value={{
        isAuthenticated,
        name,
        email,
        phone: normalizeUserInputPhone(phone),
        loading,
        login,
        logout,
        identify,
        persist,
        forget,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useUser = (): UserBag => {
  return useContext(Context);
};

export default {
  Context,
  Provider: UserProvider,
  Consumer: Context.Consumer,
};
