import React, {ReactNode} from 'react';

import fetchMultipart from 'client/utils/fetch-multipart';
import {toErrorForwarder} from 'client/utils/urls';
import debugError from 'client/utils/debug-error';
import {
  isNotFound,
  isGone,
  isChunkError,
  isNetworkError,
  isGraphQLError,
} from 'client/utils/errors';
import Page404 from 'client/pages/Page404';
import FullScreenProgress from 'client/components/Progress/FullScreenProgress';

import Ouch from '../Ouch';

interface OwnProps {
  children: ReactNode;
}

interface State {
  currentError?: Error;
  isLoading: boolean;
}

interface ErrorInfo {
  [x: string]: any;
}

class ErrorBoundary extends React.Component<OwnProps, State> {
  static async sendError(
    {message, stack, name}: Error,
    info?: ErrorInfo,
  ): Promise<Response> {
    return fetchMultipart(toErrorForwarder().serialize(), {
      message,
      stack,
      name,
      info,
    });
  }

  state: State = {currentError: undefined, isLoading: false};

  componentDidCatch(
    e: Error,
    info: {
      componentStack: string;
    },
  ): void {
    this.setState({currentError: e});

    if (
      [
        isNotFound,
        isGone,
        isNetworkError,
        isGraphQLError,
        isChunkError,
      ].some(is => is(e))
    ) {
      return;
    }

    this.sendError(e, info);
  }

  sent: {
    [message: string]: boolean;
  } = {};

  async sendError(e: Error, info: ErrorInfo): Promise<void> {
    if (this.sent[e.message]) return;

    try {
      await ErrorBoundary.sendError(e, info);
      this.sent[e.message] = true;
    } catch (error) {
      if (error instanceof Error) {
        debugError(error);
      }
    }
  }

  render(): ReactNode {
    const {children} = this.props;
    const {currentError, isLoading} = this.state;

    if (isLoading) {
      return <FullScreenProgress />;
    }

    if (isNotFound(currentError)) return <Page404 />;
    if (currentError) return <Ouch />;
    return children;
  }
}

export default ErrorBoundary;
