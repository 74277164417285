/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import {isProd} from 'client/utils/if-dev-else';

import isBrowser from '@pollex/utils/is-browser';
import {ScreenName} from 'client/contexts/Location';
import {
  TrackerAPI,
  TrackerAPIContext,
  EventKey,
  EventValue,
} from './TrackerAPI';

interface PushParams {
  key: EventKey | 'hit';
  value: {
    screenName: ScreenName;
  };
}

export default class Metrika implements TrackerAPI {
  async handleEvent(
    key: EventKey,
    value: EventValue,
    context: TrackerAPIContext,
  ): Promise<void> {
    if (key === 'quick_view') {
      this.hit();
      return;
    }

    this.push({
      key,
      value: {
        ...value,
        screenName: context.location.screenName,
        ...context.meta.experiments,
      },
    });
  }

  hit = (): void => {
    if (!isBrowser()) return;

    if (!isProd()) {
      this.logDevelopmentData({
        method: 'hit',
        key: window.location.href,
      });
    }

    // @ts-ignore
    if (window.yaCounter48110084) {
      // @ts-ignore
      window.yaCounter48110084.hit(window.location.href);
    }
  };

  push({key, value}: PushParams): void {
    if (!isProd()) {
      this.logDevelopmentData({method: 'reachGoal', key, value});
    }

    // @ts-ignore
    if (window.yaCounter48110084) {
      // @ts-ignore
      window.yaCounter48110084.reachGoal(key, value);
    }
  }

  logDevelopmentData(params: Record<string, any>): void {
    console.group('Metrika', params.method, params.key);
    if (params.value) console.table(params.value);
    console.groupEnd();
  }
}
