/* eslint-disable no-underscore-dangle */
import isBrowser from '@pollex/utils/is-browser';
import cloneDeep from 'lodash/cloneDeep';
import {SafeEnv} from 'client/types/safe-env';

export function getEnv(): SafeEnv {
  // @ts-ignore
  const env = isBrowser() ? cloneDeep(window.__ENV__) : process.env;

  return {
    VERSION: process.env.VERSION as string, // webpack-defined
    NODE_ENV: process.env.NODE_ENV as string, // webpack-defined
    HANDS_RU_GRAPHQL_ENDPOINT_CLIENT: env.HANDS_RU_GRAPHQL_ENDPOINT_CLIENT,
    HANDS_RU_BASIC_ENABLED: !!env.HANDS_RU_BASIC_ENABLED,
    HANDS_RU_BASIC_USERNAME: env.HANDS_RU_BASIC_USERNAME,
    HANDS_RU_BASIC_PASSWORD: env.HANDS_RU_BASIC_PASSWORD,
    HANDS_RU_INSTANCE_TYPE: env.HANDS_RU_INSTANCE_TYPE,
    HANDS_RU_GRAPHQL_PROXY: env.HANDS_RU_GRAPHQL_PROXY,
    HANDS_RU_INTERCOM_APP_ID: env.HANDS_RU_INTERCOM_APP_ID,
    HANDS_RU_STAT_HOST: env.HANDS_RU_STAT_HOST,
  };
}
