import noop from 'lodash/noop';
import ifDevElse from './if-dev-else';

export type DangerousFunction<ArgumentCollection extends any[], ReturnValue> = (
  ...args: ArgumentCollection
) => ReturnValue;

export type SafeFunction<ArgumentCollection extends any[], ReturnValue> = (
  ...args: ArgumentCollection
) => ReturnValue | Error;

export function safeWrap<ArgumentCollection extends any[], ReturnValue>(
  fn: DangerousFunction<ArgumentCollection, ReturnValue>,
): SafeFunction<ArgumentCollection, ReturnValue> {
  return function safe(...args): ReturnValue {
    try {
      return fn(...args);
    } catch (error: any) {
      if (error instanceof Error) {
        ifDevElse(console.error, noop)(error); // eslint-disable-line no-console
      }
      return error;
    }
  };
}

export type SilencedFunction<ArgumentCollection extends any[], ReturnValue> = (
  ...args: ArgumentCollection
) => ReturnValue | undefined | null;

export function safeSilentWrap<ArgumentCollection extends any[], ReturnValue>(
  fn: DangerousFunction<ArgumentCollection, ReturnValue>,
): SilencedFunction<ArgumentCollection, ReturnValue> {
  const wrapped = safeWrap(fn);
  return function safe(...args) {
    const result = wrapped(...args);
    if (result instanceof Error) return undefined;
    return result;
  };
}
