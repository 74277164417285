/* eslint no-underscore-dangle: off */
import 'core-js/features/array/flat-map';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import 'intersection-observer';
import assign from 'object-assign';
import ResizeObserver from 'resize-observer-polyfill';

import React from 'react';
import {hydrateRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Workbox} from 'workbox-window';
import MainApp from 'client/pages/App';
import withRoot from 'client/hocs/withRoot';
import {initApolloClient} from 'client/utils/apollo-client';
import debugError from 'client/utils/debug-error';
import {loadableReady} from '@loadable/component';
import {getEnv} from './utils/env';
import {isDev} from './utils/if-dev-else';
import fetch from './fetch';

Object.assign = assign;

async function init(): Promise<void> {
  // @ts-ignore
  const yandexApp = window?.yandex?.app;

  if (typeof window.requestAnimationFrame !== 'function') {
    const raf = await import(/* webpackChunkName: "raf" */ 'raf');
    raf.polyfill(window);
  }

  if (typeof window.ResizeObserver !== 'function') {
    window.ResizeObserver = ResizeObserver;
  }

  const Entry = withRoot({
    apolloClient: initApolloClient(),
    // @ts-ignore
    thirdLevelDomain: window.__CITY__ || '',
    // @ts-ignore
    experiments: window.__AB__ || {},
    // @ts-ignore
    referrer: window.__REFERRER__ || '',
    host: window.location.host,
    source: 'HANDS_RU',
    // @ts-ignore
    isHuman: window.__ISH__ || true,
    // @ts-ignore
    isMobile: window.__IS_MOBILE__ || false,
    signal: undefined,
    // @ts-ignore
    botType: window.__BT__ || 'N',
  })(MainApp);

  hydrateRoot(
    document.getElementById('root') as Element,
    <BrowserRouter>
      <Entry />
    </BrowserRouter>,
  );

  const env = getEnv();

  if (
    'serviceWorker' in navigator &&
    !isDev() &&
    !('Cypress' in window) &&
    !yandexApp &&
    !window?.location.href.includes('test') &&
    !window?.location.href.includes('stage')
  ) {
    try {
      const wb = new Workbox('/service-worker.js');
      wb.register();
    } catch (e) {
      if (e instanceof Error) {
        debugError(e);
      }
    }
  }

  if (yandexApp) {
    const {
      yandex: {app_version: yandexVersion},
    } = await fetch(`/manifest.json`).then(res => res.json());

    if (yandexVersion !== env.VERSION) yandexApp.forceUpdate();
  }

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept();
  }

  cleanup();
}

function cleanup(): void {
  for (const key of ['__CITY__', '__APOLLO_SCHEMA__', '__APOLLO_STATE__']) {
    delete window[key];
  }

  const node = document.getElementById('env');
  if (node?.parentNode) node.parentNode.removeChild(node);
}

loadableReady(init);
