import handsRuGetCategories from 'queries/handsRuGetCategories_2.graphql';
import {Image} from 'client/types/image';
import {CategorySlugType} from 'client/types/slug';

interface Category {
  slug: CategorySlugType;
  name: string;
  defaultText: string;
  defaultBackground: string;
  accentText: string;
  accentBackground: string;
  serviceCount: number;
  images: Image[];
  alternativeImages: Image[];
  covers: Image[];
  icons: Image[];
}

export interface HandsRuGetCategoryData {
  categories: Category[];
}

export default handsRuGetCategories;
