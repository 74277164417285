import React, {FunctionComponent} from 'react';

import invariant from 'invariant';
import City from 'client/contexts/City';
import Meta, {BotType, ExperimentConfig} from 'client/contexts/Meta';
import User from 'client/contexts/User';
import Cart from 'client/contexts/Cart';
import Order from 'client/contexts/Order';
import Location, {DEFAULT_HOST} from 'client/contexts/Location';
import Theme from 'client/contexts/Theme';
import {
  ApolloProvider,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import {withRouter} from 'react-router-dom';

import ErrorBoundary from 'client/components/ErrorBoundary';
import {OrderSource} from 'client/types/order-source';
import {EventEmitter} from 'events';

interface RootConfig {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  thirdLevelDomain: string;
  experiments: ExperimentConfig;
  host: string;
  source: OrderSource;
  isHuman: boolean;
  isMobile: boolean;
  botType: BotType;
  referrer: string;
  signal?: EventEmitter;
}

function rootWrapper({
  apolloClient,
  thirdLevelDomain = '',
  experiments = {},
  host = DEFAULT_HOST,
  source,
  isHuman = true,
  isMobile = false,
  botType,
  signal,
  referrer,
}: RootConfig) {
  return function withRoot(
    Component: FunctionComponent,
  ): ReturnType<typeof withRouter> {
    const WrappedInRoots: FunctionComponent = () => {
      invariant(apolloClient, 'No API client');

      return (
        <ErrorBoundary>
          <ApolloProvider client={apolloClient as any}>
            <Location.Provider host={host}>
              <Theme.Provider>
                <City.Provider thirdLevelDomain={thirdLevelDomain}>
                  <User.Provider>
                    <Cart.Provider>
                      <Order.Provider>
                        <Meta.Provider
                          experiments={experiments}
                          source={source}
                          isHuman={isHuman}
                          isMobile={isMobile}
                          signal={signal}
                          botType={botType}
                          referrer={referrer}
                        >
                          <Component />
                        </Meta.Provider>
                      </Order.Provider>
                    </Cart.Provider>
                  </User.Provider>
                </City.Provider>
              </Theme.Provider>
            </Location.Provider>
          </ApolloProvider>
        </ErrorBoundary>
      );
    };

    return withRouter(WrappedInRoots);
  };
}

export default rootWrapper;
