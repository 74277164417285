import {PriceUnitType, PriceType} from 'client/types/price';
import typographize from '@pollex/utils/typographize';

export default function addPrices<T extends {amount: number}>(...args: T[]): T {
  return args.reduce((totalPrice, price) => ({
    ...totalPrice,
    amount: totalPrice.amount + price.amount,
  }));
}

const NOTES = {
  DETAIL: 'Цена за деталь',
  FIRST_HOUR: 'Цена за первый час',
  HOUR: 'Цена за час',
  FLAP: 'Цена за створку',
  FOR_METER: 'Цена за метр',
  METER: 'Цена за метр',
  METER_SQUARED: 'Цена за квадратный метр',
  METER_CUBIC: 'Цена за кубический метр',
  SECTION: 'Цена за секцию',
  VISIT: 'Цена за выезд',
  PURCHASE_PERCENT: 'Цена считается в процентаx от суммы покупки',
  POSITION: 'Цена за место',
  FLAT: 'Цена за квартиру',
  TONNE: 'Цена за тонну',
  WORKING_SHIFT: 'Цена за смену',
  WINDOW: 'Цена за окно',
  CENTIMETER: 'Цена за сантиметр',
  KIT: 'Цена за комплект',
};

export function generateNote<T extends {unit: PriceUnitType}>(
  price: T,
): string {
  return typographize(NOTES[price.unit]);
}

export const EMPTY_PRICE: PriceType = {amount: 0, unit: 'FIX'};
