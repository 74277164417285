import React, {FunctionComponent} from 'react';
import CenteredElement from 'client/components/CenteredElement';
import CircularProgress from '@pollex/components/circular-progress';
import './FullScreenProgress.css';

const FullScreenProgress: FunctionComponent<any> = () => (
  <CenteredElement
    data-shmid="full-screen-progress"
    extraClassName="full-screen-progress"
  >
    <CircularProgress />
  </CenteredElement>
);

export default FullScreenProgress;
