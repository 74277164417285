import {ServicePageSlugType, GeoUnitSlugType} from 'client/types/slug';
import {CatalogPageTypeEnum} from 'client/types/page-type';
import handsRuGetProductStructuredData from 'queries/handsRuGetProductStructuredData_2.graphql';

export interface HandsRuGetProductStructuredDataData {
  catalog: {
    servicePage: {
      name: string;
      metaDescription: string;
      service: {
        price: {
          amount: number;
          unitMorphology: string;
        };
        minimumPrice: {
          amount: number;
          unitMorphology: string;
        };
      };
    };

    objectPage: {
      name: string;
      metaDescription: string;
    };

    categoryPage: {
      name: string;
      metaDescription: string;
    };
  };
}

export interface HandsRuGetProductStructuredDataVariables {
  slug: ServicePageSlugType;
  pageType: CatalogPageTypeEnum;
  geo: GeoUnitSlugType;
  isServicePage: boolean;
  isObjectPage: boolean;
  isCategoryPage: boolean;
}

export default handsRuGetProductStructuredData;
