import React, {useContext, FunctionComponent} from 'react';

import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient,
} from '@apollo/client';
import {useLocation} from 'client/contexts/Location';
import {isProd} from 'client/utils/if-dev-else';

import {useTheme} from '../Theme';
import {useMeta} from '../Meta';
import DataLayer from './trackers/DataLayer';
import GA from './trackers/GA';
import Metrika from './trackers/Metrika';
import HandsStat from './trackers/HandsStat';
import {EventKey, TrackerAPI, EventValue} from './trackers/TrackerAPI';

const Context = React.createContext<{
  handleEvent: (key: EventKey, value: EventValue) => Promise<void>;
}>({
  async handleEvent(..._unused: any): Promise<void> {
    //
  },
});

const TRACKER_APIS: TrackerAPI[] = [
  new DataLayer(),
  new GA(),
  new Metrika(),
  new HandsStat(),
];

const AnalyticsProvider: FunctionComponent<{
  apolloClient: ApolloClient<NormalizedCacheObject>;
}> = ({children, apolloClient}) => {
  const theme = useTheme();
  const meta = useMeta();
  const location = useLocation();

  const handleEvent = async (
    key: EventKey,
    value: EventValue,
  ): Promise<void> => {
    await Promise.all(
      TRACKER_APIS.map(provider =>
        provider.handleEvent(key, value, {
          location,
          theme,
          apolloClient,
          meta,
        }),
      ),
    ).catch(e => {
      /* eslint-disable no-console */
      if (!isProd()) console.error(e);
      /* eslint-enable no-console */
    });
  };

  return <Context.Provider value={{handleEvent}}>{children}</Context.Provider>;
};

const AnalyticsProviderWrapper: FunctionComponent = ({children}) => {
  const apolloClient = useApolloClient();
  return (
    <AnalyticsProvider apolloClient={apolloClient as any}>
      {children}
    </AnalyticsProvider>
  );
};

export interface AnalyticsBag {
  handleEvent: (key: EventKey, value: EventValue) => () => Promise<void>;
}

export function useAnalytics(): AnalyticsBag {
  const context = useContext(Context);

  return {
    handleEvent(...args) {
      return async () => {
        context.handleEvent(...args);
      };
    },
  };
}

export default {
  Context,
  Consumer: Context.Consumer,
  Provider: AnalyticsProviderWrapper,
};
