export default function formatNumber(
  _value: number,
  fractionDigits = 0,
  delimiter = ',',
): string {
  const value = Number(_value.toFixed(fractionDigits));

  const integer =
    value < 10000
      ? `${Math.floor(value)}`
      : `${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\xa0');

  const fraction =
    Number.isInteger(value) || fractionDigits === 0
      ? ''
      : `${delimiter}${`${value}`.split('.')[1]}`;

  return `${integer}${fraction}`;
}
