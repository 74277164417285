/* eslint-disable class-methods-use-this */
import {isProd} from 'client/utils/if-dev-else';
import {ScreenName} from 'client/contexts/Location';
import {
  TrackerAPI,
  TrackerAPIContext,
  EventKey,
  EventValue,
} from './TrackerAPI';

interface PushParams {
  eventAction: EventKey;
  eventCategory: ScreenName;
  eventLabel?: string | number;
}

export default class GA implements TrackerAPI {
  static GA_APP_ID = 'G-LVQ32X4KMN';

  async handleEvent(
    key: EventKey,
    value: EventValue,
    context: TrackerAPIContext,
  ): Promise<void> {
    if (key === 'quick_view') return;
    const params = GA.makeParams(key, value, context);
    this.logDevelopmentData(params);
    this.push(params);
  }

  static makeParams(
    key: EventKey,
    value: EventValue,
    context: TrackerAPIContext,
  ): PushParams {
    const base = {
      eventAction: key,
      eventCategory: context.location.screenName,
    };

    switch (key) {
      case 'search_abandon':
      case 'search_custom_option_click': {
        return {...base, eventLabel: value.query};
      }

      case 'search_click': {
        const {optionIndex, query, chosen} = value;
        return {
          ...base,
          eventLabel: GA.makeLabel([optionIndex, query, chosen]),
        };
      }

      case 'service_click': {
        const {serviceSlug, objectSlug, categorySlug} = value;

        return {
          ...base,
          eventLabel: GA.makeLabel([serviceSlug, objectSlug, categorySlug]),
        };
      }

      case 'order_submit':
      case 'order_leroy_submit': {
        return {
          ...base,
          eventLabel: value.hash,
        };
      }

      case 'order_partner_submit': {
        return {
          ...base,
          eventLabel: GA.makeLabel([value.partner, value.hash]),
        };
      }

      case 'get_app_button_click':
      case 'classifier_button_click': {
        return {
          ...base,
          eventLabel: value.type,
        };
      }

      case 'order_continue_click':
      case 'order_instant_order_click':
      case 'cart_delete_item_click':
      case 'cart_instantly_add_service_click':
      case 'wizard_resources_click_next':
      case 'order_to_cart_click': {
        return {
          ...base,
          eventLabel: GA.makeLabel([value.serviceSlug, value.categorySlug]),
        };
      }

      case 'cart_order_tunnel_click':
        return {
          ...base,
          eventLabel: GA.makeLabel([value.case]),
        };

      case 'main_search_link_click':
      case 'main_category_click': {
        return {
          ...base,
          eventLabel: GA.makeLabel([value.slug]),
        };
      }

      case 'object_click': {
        return {
          ...base,
          eventLabel: GA.makeLabel([value.objectSlug, value.categorySlug]),
        };
      }

      case 'footer_link_click':
      case 'order_navigation_category_click':
      case 'order_navigation_object_click':
      case 'order_navigation_service_click': {
        return {
          ...base,
          eventLabel: value.href,
        };
      }

      case 'city_selector_choose_city':
      case 'city_select_choose_other_city': {
        return {
          ...base,
          eventLabel: value.city,
        };
      }

      case 'wizard_choose_free_input': {
        return {
          ...base,
          eventLabel: GA.makeLabel([
            value.slug,
            value.questionId,
            value.questionType,
            value.fieldId,
            value.freeInput,
          ]),
        };
      }

      case 'wizard_choose_quantity': {
        return {
          ...base,
          eventLabel: GA.makeLabel([
            value.slug,
            value.questionId,
            value.questionType,
            value.fieldId,
            value.freeInput,
            value.value,
          ]),
        };
      }

      case 'wizard_choose_answer': {
        return {
          ...base,
          eventLabel: GA.makeLabel([
            value.slug,
            value.questionId,
            value.questionType,
            value.fieldId,
          ]),
        };
      }

      case 'wizard_click_next':
      case 'wizard_start': {
        return {
          ...base,
          eventLabel: GA.makeLabel([value.slug, value.questionId]),
        };
      }

      case 'wizard_resources_input': {
        return {
          ...base,
          eventLabel: GA.makeLabel([
            value.slug,
            value.resource,
            value.quantity,
          ]),
        };
      }

      case 'wizard_stop':
      case 'wizard_commentaries_comment_input':
      case 'wizard_commentaries_file_input':
      case 'order_click_next':
      case 'category_page_link_click':
      case 'object_page_link_click':
      case 'service_page_link_click':
      case 'price_list_click':
      case 'category_click':
      case 'main_service_click':
      case 'specialist_heading_link_click':
      case 'specialist_order_link_click':
      case 'specialist_available_order_category_click':
      case 'where_to_repair_click': {
        return {
          ...base,
          eventLabel: value.slug,
        };
      }

      case 'specialist_submit':
      case 'specialist_submit_dveri':
      case 'specialist_submit_kuhni':
      case 'fulltimer_submit': {
        return {
          ...base,
          eventLabel: value.id,
        };
      }

      case 'specialist_vacancy_submit':
        return {...base, eventLabel: GA.makeLabel([value.login, value.step])};

      case 'notice_click': {
        return {
          ...base,
          eventLabel: value.thirdLevelDomain,
        };
      }

      case 'review_choose_nps': {
        return {
          ...base,
          eventLabel: value.nps,
        };
      }

      case 'tab_click': {
        return {
          ...base,
          eventLabel: value.tab,
        };
      }

      case 'other_link_click':
      case 'merged_page_aside_click': {
        return {
          ...base,
          eventLabel: value.href,
        };
      }

      case 'merged_page_scroll': {
        return {
          ...base,
          eventLabel: value.section,
        };
      }

      default:
        return base;
    }
  }

  static gtag(...args) {
    // @ts-ignore
    if (!window.gtag) {
      const gtag = function gtag(...a) {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(a);
      };
      gtag('js', new Date());
      gtag('config', GA.GA_APP_ID);
      // @ts-ignore
      window.gtag = gtag;
    }

    // @ts-ignore
    window.gtag(...args);
  }

  static makeLabel(data: (string | number | void)[]): string {
    return data.filter(Boolean).join(' | ');
  }

  static getClientId(): string | undefined {
    if (!isProd()) {
      return;
    }
    try {
      // @ts-ignore
      let clientID;

      GA.gtag('get', GA.GA_APP_ID, 'client_id', id => {
        clientID = id;
      });
      return clientID;
    } catch (e) {
      //
    }
  }

  push(params: PushParams): void {
    GA.gtag('event', params.eventAction, {
      screen_name: params.eventCategory,
      event_label: params.eventLabel,
    });
  }

  /* eslint-disable no-console */
  logDevelopmentData(params: PushParams): void {
    if (isProd()) return;
    console.group('GA event', params.eventAction);
    console.table(params);
    console.groupEnd();
  }
  /* eslint-enable no-console */
}
