import React, {useContext, FunctionComponent, useEffect, useState} from 'react';

import isBrowser from '@pollex/utils/is-browser';
import {useLocation as useRouterLocation, useHistory} from 'react-router-dom';
import * as H from 'history';

import {
  screenNameFromLocation,
  getQuery,
  LocationBag,
  LocationStateType,
  LocationQueryType,
} from './utils';

export {screenNameFromLocation, catalogItemFromLocation} from './utils';

export type {
  ScreenName,
  LocationBag,
  LocationStateType,
  LocationQueryType,
} from './utils';

interface OwnProps {
  host: string;
}

const defaultHistory: H.History = isBrowser()
  ? H.createBrowserHistory()
  : H.createMemoryHistory();

export const DEFAULT_STATE: LocationStateType = {
  screenName: 'main',
  query: {},
};

export const DEFAULT_HOST = 'hands.ru';

export const DEFAULT_BAG: LocationBag = {
  ...DEFAULT_STATE,
  location: defaultHistory.location,
  history: defaultHistory,
  host: DEFAULT_HOST,
};

const Context = React.createContext<string>(DEFAULT_HOST);

const LocationProvider: FunctionComponent<OwnProps> = ({children, host}) => {
  return <Context.Provider value={host}>{children}</Context.Provider>;
};

const Location = {
  Context,
  Consumer: Context.Consumer,
  Provider: LocationProvider,
};

export function useLocation(): LocationBag {
  const location = useRouterLocation();
  const host = useContext(Context);
  const screenName = screenNameFromLocation(location);
  const history = useHistory();
  const {search = ''} = location;

  const [query, setQuery] = useState<LocationQueryType>(() =>
    getQuery({search}),
  );

  useEffect(() => {
    setQuery(getQuery({search}));
  }, [search]);

  return {
    history,
    location,
    query,
    screenName,
    host,
  };
}

export default Location;
