import React, {useEffect, FunctionComponent, useCallback} from 'react';

import {LocationBag, useLocation} from 'client/contexts/Location';
import usePreviousValue from '@pollex/hooks/use-previous-value';
import Intercom from 'client/contexts/Analytics/trackers/Intercom';
import {useAnalytics} from 'client/contexts/Analytics';
import useIntercomOnPresent from 'client/hooks/use-intercom-on-present';

const INTERACTIONS = ['mouseover', 'touchstart'];

const PageChangeHandler: FunctionComponent = ({children}) => {
  const location = useLocation();
  const previous = usePreviousValue<LocationBag['location']>(location.location);
  const {handleEvent} = useAnalytics();
  const onPresent = useCallback(() => {
    Intercom.run('onShow', handleEvent('intercom_open', {}));
    Intercom.run('onHide', handleEvent('intercom_close', {}));
  }, []);

  useIntercomOnPresent(onPresent);

  const unsubscribeFromInteractions = (): void => {
    for (const name of INTERACTIONS) {
      window.removeEventListener(name, listener);
    }
  };

  const listener = useCallback(() => {
    if (Intercom.BLACKLISTED_PAGES.includes(location.screenName)) {
      return;
    }
    handleEvent('first_interaction', {})().then(unsubscribeFromInteractions);
  }, []);

  useEffect(() => {
    for (const name of INTERACTIONS) {
      window.addEventListener(name, listener);
    }

    return unsubscribeFromInteractions;
  }, []);

  useEffect(() => {
    const pathnameChanged =
      location.location.pathname !== (previous && previous.pathname);

    if (Intercom.BLACKLISTED_PAGES.includes(location.screenName)) {
      Intercom.hide();
      return;
    }

    if (pathnameChanged) {
      window.scroll(0, 0);
    }

    if (pathnameChanged) {
      // @ts-ignore
      if (typeof window.callibriInit === 'function') {
        // @ts-ignore
        window.callibriInit();
      }
    }
  });

  return <>{children}</>;
};

export default PageChangeHandler;
