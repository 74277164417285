import {CatalogDataTypeEnum} from 'client/types/page-type';

const TYPE_TO_PAGE = {
  SERVICE: 'servicePage' as const,
  OBJECT: 'objectPage' as const,
  CATEGORY: 'categoryPage' as const,
  SPECIALIST: 'specialistPage' as const,
};

const LOWER_CASE_TO_TYPE: Record<string, CatalogDataTypeEnum> = {
  category: 'CATEGORY',
  service: 'SERVICE',
  object: 'OBJECT',
  profile: 'SPECIALIST',
};

export function dataTypeToPage<T extends CatalogDataTypeEnum>(
  dataType: T,
): typeof TYPE_TO_PAGE[T] {
  return TYPE_TO_PAGE[dataType];
}

export function lowerCaseTypeToDataType(
  lowerCaseType: string,
): CatalogDataTypeEnum {
  return LOWER_CASE_TO_TYPE[lowerCaseType];
}
