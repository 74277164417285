import {getEnv} from 'client/utils/env';

export default function ifDevElse<D, N>(dev: D, nondev: N): D | N {
  if (isDev()) {
    return dev;
  }

  return nondev;
}

export function ifDevOrTestElse(dev: any, nondev: any): boolean {
  if (isDev() || isTest()) {
    return dev;
  }
  return nondev;
}

export function isDev(): boolean {
  const env = getEnv();
  return env.NODE_ENV === 'development' || env.HANDS_RU_INSTANCE_TYPE === 'DEV';
}

export function isTest(): boolean {
  const env = getEnv();
  return env.HANDS_RU_INSTANCE_TYPE === 'TEST';
}

export function isStage(): boolean {
  const env = getEnv();
  return env.HANDS_RU_INSTANCE_TYPE === 'STAGE';
}

export function isProd(): boolean {
  const env = getEnv();
  return env.HANDS_RU_INSTANCE_TYPE === 'PROD';
}
