/* eslint-disable max-classes-per-file */
import React, {ReactNode, ComponentType} from 'react';

import {Route} from 'react-router-dom';
import loadable from 'client/utils/loadable';

const LazyPageCompanyAbout = loadable(
  () => import(/* webpackChunkName: "meta" */ 'client/pages/PageCompanyAbout'),
);
const LazyPageCompanyContacts = loadable(
  () =>
    import(/* webpackChunkName: "meta" */ 'client/pages/PageCompanyContacts'),
);
const LazyPageVacancies = loadable(
  () => import(/* webpackChunkName: "meta" */ 'client/pages/PageVacancies'),
);
const LazyPageStatic = loadable(
  () => import(/* webpackChunkName: "meta" */ 'client/pages/PageStatic'),
);
const LazyPageStaticDocuments = loadable(
  () =>
    import(/* webpackChunkName: "meta" */ 'client/pages/PageStaticDocuments'),
);
const LazyPage404 = loadable(
  () => import(/* webpackChunkName: "not-found" */ 'client/pages/Page404'),
);
const LazyPageMain = loadable(
  () => import(/* webpackChunkName: "main" */ 'client/pages/PageMain'),
);
const LazyPageOrderSuccess = loadable(
  () =>
    import(/* webpackChunkName: "customer" */ 'client/pages/PageOrderSuccess'),
);
const LazyPageClientOrders = loadable(
  () =>
    import(/* webpackChunkName: "customer" */ 'client/pages/PageClientOrders'),
);
const LazyPageClientCart = loadable(
  () =>
    import(/* webpackChunkName: "customer" */ 'client/pages/PageClientCart'),
);
const LazyPageOrderDates = loadable(
  () =>
    import(/* webpackChunkName: "customer" */ 'client/pages/PageOrderDates'),
);
const LazyPageAllReviews = loadable(
  () =>
    import(
      /* webpackChunkName: "catalog-reviews" */ 'client/pages/PageAllReviews'
    ),
);
const LazyPageReviews = loadable(
  () =>
    import(
      /* webpackChunkName: "catalog-reviews" */ 'client/pages/PageReviews'
    ),
);
const LazyPageService = loadable(
  () => import(/* webpackChunkName: "catalog" */ 'client/pages/PageService'),
);
const LazyPageCalculator = loadable(
  () =>
    import(
      /* webpackChunkName: "catalog-calculator" */ 'client/pages/PageCalculator'
    ),
);
const LazyPagePriceList = loadable(
  () => import(/* webpackChunkName: "catalog" */ 'client/pages/PagePriceList'),
);
const LazyPageObject = loadable(
  () => import(/* webpackChunkName: "catalog" */ 'client/pages/PageObject'),
);
const LazyPageCategory = loadable(
  () => import(/* webpackChunkName: "catalog" */ 'client/pages/PageCategory'),
);
const LazyPageClassifier = loadable(
  () => import(/* webpackChunkName: "catalog" */ 'client/pages/PageClassifier'),
);
const LazyPageSpecialistProfile = loadable(
  () =>
    import(
      /* webpackChunkName: "catalog" */ 'client/pages/PageSpecialistProfile'
    ),
);
const LazyPageSpecialistRegister = loadable(
  () =>
    import(
      /* webpackChunkName: "forms" */ 'client/pages/PageSpecialistRegister'
    ),
);

const LazyPageSystemReviewForm = loadable(
  () =>
    import(/* webpackChunkName: "forms" */ 'client/pages/PageSystemReviewForm'),
);
const LazyPageFulltimerRegisterForm = loadable(
  () =>
    import(
      /* webpackChunkName: "forms" */ 'client/pages/PageFulltimerRegisterForm'
    ),
);
const LazyPageSpecialistRegisterForm = loadable(
  () =>
    import(
      /* webpackChunkName: "forms" */ 'client/pages/PageSpecialistRegisterForm'
    ),
);
const LazyPageSpecialistRegisterSuccess = loadable(
  () =>
    import(
      /* webpackChunkName: "forms" */ 'client/pages/PageSpecialistRegisterSuccess'
    ),
);
const LazyPageSpecialistDocuments = loadable(
  () =>
    import(
      /* webpackChunkName: "forms" */ 'client/pages/PageSpecialistDocuments'
    ),
);

const LazyPageCompensationForm = loadable(
  () =>
    import(
      /* webpackChunkName: "forms" */ 'client/pages/PageClientCompensationForm'
    ),
);

const LazyPageAuchanPartnerForm = loadable(
  () =>
    import(
      /* webpackChunkName: "partner" */ 'client/pages/PageAuchanOrderForm'
    ),
);

const LazyPageGenericPartnerForm = loadable(
  () =>
    import(
      /* webpackChunkName: "partner" */ 'client/pages/PageGenericPartnerForm'
    ),
);

export type PageComponent = ComponentType<any>;

export type PageRoute = Readonly<{
  path: string;
  exact: boolean;
  component: PageComponent;
  key: string;
}>;

export const STATIC_ROUTES: PageRoute[] = [
  {
    path: '/',
    component: LazyPageMain,
    key: 'PageMain',
    exact: true,
  },
  {
    path: '/index.html',
    component: LazyPageMain,
    key: 'PageMainShell',
    exact: true,
  },
  {
    path: '/company/about/',
    component: LazyPageCompanyAbout,
    key: 'PageCompanyAbout',
    exact: true,
  },
  {
    path: '/client/orders/',
    component: LazyPageClientOrders,
    key: 'PageClientOrders',
    exact: true,
  },
  {
    path: '/client/orders/edit/',
    component: LazyPageOrderDates,
    key: 'PageOrderDates',
    exact: true,
  },
  {
    path: '/client/cart/',
    component: LazyPageClientCart,
    key: 'PageClientCart',
    exact: true,
  },
  {
    path: '/company/contact-us/',
    component: LazyPageCompanyContacts,
    key: 'PageCompanyContacts',
    exact: true,
  },
  {
    path: '/vacancies/',
    component: LazyPageVacancies,
    key: 'PageVacancies',
    exact: true,
  },
  {
    path: '/documents/:slug/',
    component: LazyPageStaticDocuments,
    key: 'PageStaticDocuments',
    exact: true,
  },
  {
    path: '/:slug/',
    component: LazyPageStatic,
    key: 'PageStatic',
    exact: false,
  },
  {
    path: '*',
    component: LazyPage404,
    key: 'NonExistingPath',
    exact: false,
  },
];

export const BACKWARD_COMPAT_ROUTES: PageRoute[] = [
  {
    path: '/review/add/',
    component: LazyPageSystemReviewForm,
    key: 'PageSystemReviewForm',
    exact: true,
  },
];

export const PARTNER_ROUTES: PageRoute[] = [
  {
    path: '/pik/order/',
    component: LazyPageGenericPartnerForm,
    key: 'PagePikForm',
    exact: true,
  },
  {
    path: '/melana/order/',
    component: LazyPageGenericPartnerForm,
    key: 'PageMelanaForm',
    exact: true,
  },
  {
    path: '/ampm/order/',
    component: LazyPageGenericPartnerForm,
    key: 'PageAmpmForm',
    exact: true,
  },
  {
    path: '/auchan/order/',
    component: LazyPageAuchanPartnerForm,
    key: 'PageAuchanForm',
    exact: true,
  },
  {
    path: '/partner/order/',
    component: LazyPageGenericPartnerForm,
    key: 'PageGenericPartnerForm',
    exact: true,
  },
];

export const DYNAMIC_ROUTES = [
  {
    path: '/category/',
    component: LazyPageClassifier,
    key: 'PageClassifierCategory',
    exact: true,
  },

  {
    path: '/reviews/',
    component: LazyPageAllReviews,
    key: 'PageAllReviews',
    exact: true,
  },

  {
    path: '/:type/:slug/reviews/',
    component: LazyPageReviews,
    key: 'PageReviews',
    exact: true,
  },

  {
    path: '/:type/:slug/price/',
    component: LazyPagePriceList,
    key: 'PagePriceList',
    exact: true,
  },

  {
    path: '/service/:slug/',
    component: LazyPageService,
    key: 'PageService',
    exact: true,
  },

  {
    path: '/service/:slug/smeta/',
    component: LazyPageCalculator,
    key: 'PageCalculator',
    exact: true,
  },

  {
    path: '/service/:slug/:geo/',
    component: LazyPageService,
    key: 'PageServiceWithGeo',
    exact: true,
  },

  {
    path: '/object/:slug/',
    component: LazyPageObject,
    key: 'PageObject',
    exact: true,
  },
  {
    path: '/object/:slug/:geo/',
    component: LazyPageObject,
    key: 'PageSEOObjectWIthGeo',
    exact: true,
  },

  {
    path: '/category/:slug/',
    component: LazyPageCategory,
    key: 'PageCategory',
    exact: true,
  },

  {
    path: '/category/:slug/geo/',
    component: LazyPageCategory,
    key: 'PageCategoryGeoHub',
    exact: true,
  },

  {
    path: '/category/:slug/geo/:geo/',
    component: LazyPageCategory,
    key: 'PageCategoryWithGeo',
    exact: true,
  },

  {
    path: '/success/',
    component: LazyPageOrderSuccess,
    key: 'PageOrderSuccess',
    exact: true,
  },

  {
    path: '/profile/:slug/',
    component: LazyPageSpecialistProfile,
    key: 'PageSpecialistProfile',
    exact: true,
  },
];

export const BACKOFFICE_ROUTES: PageRoute[] = [
  {
    path: '/specialist/',
    component: LazyPageSpecialistRegister,
    key: 'PageSpecialistRegister',
    exact: true,
  },
  {
    path: '/specialist/documents/',
    component: LazyPageSpecialistDocuments,
    key: 'PageSpecialistDocumentInfo',
    exact: true,
  },
  {
    path: '/specialist/documents/:step/',
    component: LazyPageSpecialistDocuments,
    key: 'PageSpecialistDocuments',
    exact: true,
  },
  {
    path: '/fulltimer/register/',
    component: LazyPageFulltimerRegisterForm,
    key: 'PageFulltimerRegisterForm',
    exact: true,
  },
  {
    path: '/specialist/register/',
    component: LazyPageSpecialistRegisterForm,
    key: 'PageSpecialistRegisterForm',
    exact: true,
  },
  {
    path: '/specialist/:slug/register/',
    component: LazyPageSpecialistRegisterForm,
    key: 'PageSpecialistRegisterFormSpecialized',
    exact: true,
  },
  {
    path: '/specialist/success/',
    component: LazyPageSpecialistRegisterSuccess,
    key: 'PageSpecialistRegisterSuccess',
    exact: true,
  },
  {
    path: '/specialist/:slug/',
    component: LazyPageSpecialistRegister,
    key: 'PageSpecialistRegisterSpecialized',
    exact: true,
  },
  {
    path: '/compensation/',
    component: LazyPageCompensationForm,
    key: 'PageCompensationForm',
    exact: true,
  },
];

export const ROUTER_CONFIG: PageRoute[] = [
  ...BACKWARD_COMPAT_ROUTES,
  ...PARTNER_ROUTES,
  ...DYNAMIC_ROUTES,
  ...BACKOFFICE_ROUTES,
  ...STATIC_ROUTES,
];

export const ROUTES: ReactNode[] = ROUTER_CONFIG.map(r => (
  <Route
    path={r.path}
    exact={r.exact}
    key={r.key}
    component={r.component as any}
  />
));
