import clamp from 'lodash/clamp';

export type RGB = [number, number, number];

export function dehash(color: string): string {
  return color
    .replace('#', '')
    .replace('.', '-')
    .replace('%', '_percent')
    .toUpperCase();
}

export function hexToRGB(rawColor: string | RGB): RGB {
  if (Array.isArray(rawColor)) return rawColor;

  const color = rawColor.substr(1);

  const re = new RegExp(`.{1,${color.length / 3}}`, 'g');

  let colors = color.match(re) || [];

  if (colors[0] && colors[0].length === 1) {
    // @ts-ignore
    colors = colors.map(n => n + n);
  }

  const [r, g, b] = colors.map(n => parseInt(n, 16));
  return [r, g, b];
}

export function rgba(...numbers: number[]): string {
  const [r, g, b, a] = numbers;
  if (a && a !== 1) {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
}

function invertCoefficient(coefficient: number): number {
  return 1 - coefficient;
}

export function getLuminance(color: string | RGB): number {
  const [r, g, b] = hexToRGB(color).map(value => {
    const luminanceCoefficient = value / 255;
    return luminanceCoefficient <= 0.03928
      ? luminanceCoefficient / 12.92
      : ((luminanceCoefficient + 0.055) / 1.055) ** 2.4;
  });

  return Number((0.2126 * r + 0.7152 * g + 0.0722 * b).toFixed(3));
}

export function emphasize(color: string | RGB, coefficient: number): RGB {
  if (getLuminance(color) > 0.5) return darken(color, coefficient);
  return lighten(color, coefficient);
}

export function darken(color: string | RGB, coefficient: number): RGB {
  const [r, g, b] = hexToRGB(color).map(value =>
    clamp(255, 0, Math.floor(value * invertCoefficient(coefficient))),
  );

  return [r, g, b];
}

export function lighten(color: string | RGB, coefficient: number): RGB {
  const [r, g, b] = hexToRGB(color).map(value =>
    clamp(255, 0, Math.floor(value / invertCoefficient(coefficient))),
  );

  return [r, g, b];
}
