import fetch from 'client/fetch';

export default async function fetchMultipart<T extends Record<string, any>>(
  url: string,
  params: T,
): Promise<Response> {
  const formData = new FormData();

  for (const [key, value] of Object.entries(params)) {
    formData.append(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    );
  }

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: formData,
  });
}
