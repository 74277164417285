/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import {isProd} from 'client/utils/if-dev-else';
import isBrowser from '@pollex/utils/is-browser';
import {toStat} from 'client/utils/urls';
import fetchMultipart from 'client/utils/fetch-multipart';
import {getEnv} from 'client/utils/env';
import {
  TrackerAPI,
  TrackerAPIContext,
  EventKey,
  EventValue,
} from './TrackerAPI';

export default class HandsStat implements TrackerAPI {
  async handleEvent(
    key: EventKey,
    value: EventValue,
    {location: {screenName}, meta: {experiments, isMobile}}: TrackerAPIContext,
  ): Promise<void> {
    const data = {
      name: key,
      parameters: value,
      experiments,
      screenName,
      isMobile,
      url: window.location.href,
      referrer: document.referrer,
    };

    if (!isProd()) this.logDevelopmentData({key, data});

    if (!isBrowser() || !getEnv().HANDS_RU_STAT_HOST) return;

    try {
      await fetchMultipart(toStat().serialize(), data);
    } catch (e) {
      if (!isProd()) console.warn(e);
    }
  }

  logDevelopmentData({
    key,
    data: {parameters, referrer, experiments, screenName, url, isMobile},
  }: Record<string, any>): void {
    console.group('Hands Stat Event', key);
    // @ts-ignore
    console.table(experiments, parameters, {
      screenName,
      referrer,
      url,
      isMobile,
    });
    console.groupEnd();
  }
}
