import React, {
  useContext,
  ReactNode,
  FunctionComponent,
  useCallback,
  useReducer,
  useEffect,
} from 'react';

import noop from 'lodash/noop';
import * as storage from '@pollex/utils/safe-local-storage';
import {
  IdentifyOrder,
  PersistOrder,
  SetServiceSlug,
  FlushState,
  OrderStateType,
  OrderBag,
  SetError,
} from './types';

export * from './types';

interface OwnProps {
  children: ReactNode;
}

export const DEFAULT_STATE: OrderStateType = {
  serviceSlug: null,
  error: undefined,
};

const Context = React.createContext<OrderBag>({
  ...DEFAULT_STATE,
  setServiceSlug: noop,
  flushState: noop,
  persist: noop,
  identify: noop,
  setError: noop,
});

function reducer(state: OrderStateType, action) {
  switch (action.type) {
    case 'ADD_SERVICE_SLUG':
      return {...state, serviceSlug: action.serviceSlug};

    case 'SET_ERROR':
      return {...state, error: action.error};

    case 'FLUSH_STATE':
      return DEFAULT_STATE;

    default:
      return state;
  }
}

const OrderProvider: FunctionComponent<OwnProps> = ({children}: OwnProps) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  useEffect(() => {
    const order = storage.get('order');
    if (order) identify(order);
  }, []);

  useEffect(() => {
    if (state) persist();
  }, [state]);

  const setServiceSlug: SetServiceSlug = useCallback(serviceSlug => {
    dispatch({type: 'ADD_SERVICE_SLUG', serviceSlug});
  }, []);

  const flushState: FlushState = useCallback(() => {
    dispatch({type: 'FLUSH_STATE'});
  }, []);

  const setError: SetError = useCallback(error => {
    dispatch({type: 'SET_ERROR', error});
  }, []);

  const persist: PersistOrder = useCallback((): void => {
    storage.set('order', state);
  }, [state]);

  const identify: IdentifyOrder = useCallback(({serviceSlug}): void => {
    if (serviceSlug) setServiceSlug(serviceSlug);
  }, []);

  return (
    <Context.Provider
      value={{
        persist,
        identify,
        setServiceSlug,
        flushState,
        setError,
        serviceSlug: state.serviceSlug,
        error: state.error,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useOrder = (): OrderBag => {
  return useContext(Context);
};

export default {
  Context,
  Provider: OrderProvider,
  Consumer: Context.Consumer,
};
